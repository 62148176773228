const _jsxFileName = "/home/runner/work/asew-klimazaehler-admin/asew-klimazaehler-admin/src/js/klimazaehler/components/GlobalComponent.tsx";import React from "react";
import { createComponent } from "@opendash/state";

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Roboto",apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,Noto Sans,sans-serif;
  }

  .ant-form-item {
    margin-bottom: 28px;
  }
`;

export const GlobalComponent = createComponent(function GlobalComponent() {
  return (
    React.createElement(React.Fragment, null
      , React.createElement(GlobalStyle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} )
    )
  );
});
