const _jsxFileName = "/home/runner/work/asew-klimazaehler-admin/asew-klimazaehler-admin/src/js/klimazaehler/components/CollapseNachweise.tsx";import React, { useMemo, } from "react";

import { Button, Collapse, Divider, Flex, Tag, Typography } from "antd";



import { Icon } from "@opendash/icons";





const CollapseNachweise = (props) => {
  const filteredNachweise = useMemo(() => {
    return props.state.massnahmenNachweise.filter((nachweise) => {
      return props.state.year === 0
        ? true
        : nachweise.get("jahr") === props.state.year;
    });
  }, [props.state.year, props.state.massnahmenNachweise]);

  const onChange = (key) => {};

  const items = [
    {
      key: "1",
      label: "Nachweise",
      children: filteredNachweise.map(
        (nachweis, index) => (
          React.createElement(React.Fragment, null
            , React.createElement(Flex, { key: index, justify: "space-between", wrap: "wrap", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
              , React.createElement(Typography.Text, { style: { flex: "0 0 100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
                , nachweis.massnahme.kategorie.get("bezeichnung")
              )

              , React.createElement(Flex, { gap: "4px 0" , wrap: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
                , React.createElement(Tag, { color: nachweis.massnahme.kategorie.kategorie.farbe, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
                  , nachweis.massnahme.kategorie.kategorie.bezeichnung
                )
                , props.state.year === 0 && (
                  React.createElement(Tag, { color: "gray", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}, nachweis.get("jahr"))
                )
              )
              , React.createElement(Button, {
                onClick: () => props.state.downloadNachweis(nachweis),
                size: "small", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}

                , React.createElement(Typography.Text, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
                  , nachweis.massnahme.bezeichnung
                )
                , React.createElement(Icon, { icon: "fa:download", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}} )
              )
            )
            , filteredNachweise.length - 1 !== index && React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} )
          )
        )
        //console.log(filteredNachweise)
      ),
    },
  ];

  return (
    React.createElement(Collapse, { items: items, onChange: onChange, style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}} )
  );
};

export default CollapseNachweise;
