import Parse from "parse";




















export class UnterKategorie extends Parse.Object {
  static __initStatic() {this.className = "KlimaZaehler_UnterKategorie"}

  constructor(data) {
    super("KlimaZaehler_UnterKategorie", data );
  }

  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get einheit() {
    return super.get("einheit");
  }
  set einheit(value) {
    super.set("einheit", value);
  }
  get faktoren() {
    return super.get("faktoren");
  }
  set faktoren(value) {
    super.set("faktoren", value);
  }
  get felder() {
    return super.get("felder");
  }
  set felder(value) {
    super.set("felder", value);
  }
  get felderKurz() {
    return super.get("felderKurz");
  }
  set felderKurz(value) {
    super.set("felderKurz", value);
  }
  get formel() {
    return super.get("formel");
  }
  set formel(value) {
    super.set("formel", value);
  }
  get kachel() {
    return super.get("kachel");
  }
  set kachel(value) {
    super.set("kachel", value);
  }
  get kategorie() {
    return super.get("kategorie");
  }
  set kategorie(value) {
    super.set("kategorie", value);
  }
} UnterKategorie.__initStatic();

Parse.Object.registerSubclass("KlimaZaehler_UnterKategorie", UnterKategorie);
