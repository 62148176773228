 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { downloadCSV } from "@opendash/core";
import { translate, translateSync } from "@opendash/i18n";
import { Parse } from "@opendash/plugin-parse";
import { message } from "antd";
import { parse, unparse as toCSV } from "papaparse";
import {
  Massnahme,
  MassnahmeBerechnung,

  MassnahmeNachweis,
  UnterKategorie,
} from "../types-generated";











export class ImportExportService {
  

   __init() {this.attributes = [
    "jahr",
    "faktor",
    "absatz",
    "absatz_kg",
    "adresse",
    "anteilBioerdgas",
    "anzahl",
    "brennstoffeinsatz",
    "defaultFaktor",
    "emissionsfaktor",
    "erzeugterStrom",
    "erzeugteWaerme",
    "fahrleistung",
    "foerdervolumen",
    "kommentar",
    "kundenanzahl",
    "kundengruppe",
    "menge",
    "nennleistung",
    "oekofondAufschlag",
    "oekofondEinnahmen",
    "personenkm",
  ]}

   __init2() {this.attributesOfTypeNumber = [
    "absatz",
    "absatz_kg",
    "anteilBioerdgas",
    "anzahl",
    "brennstoffeinsatz",
    "defaultFaktor",
    "emissionsfaktor",
    "erzeugterStrom",
    "erzeugteWaerme",
    "fahrleistung",
    "faktor",
    "foerdervolumen",
    "jahr",
    "kundenanzahl",
    "menge",
    "nennleistung",
    "oekofondAufschlag",
    "oekofondEinnahmen",
    "personenkm",
  ]}

   __init3() {this.attributesOfTypeArray = ["kundengruppe"]}

  constructor(app) {;ImportExportService.prototype.__init.call(this);ImportExportService.prototype.__init2.call(this);ImportExportService.prototype.__init3.call(this);
    this.app = app;
  }

   async test() {
    const csv = await this.export();

    await this.import(csv);
  }

   async import(csvWithMeta) {
    const hide = message.loading("Daten werden importiert...", 0);

    try {
      await translate("klimazaehler:init");

      const tenant = (await this.app.getTenant()) ;

      const unterkategorien = await new Parse.Query(UnterKategorie)
        .include("kategorie")
        .limit(1000000)
        .find();

      const massnahmen = await new Parse.Query(Massnahme)
        .equalTo("tenant", tenant)
        .ascending("bezeichnung")
        .include("kategorie")
        .limit(1000000)
        .find();

      const massnahmenJahre = await new Parse.Query(MassnahmeBerechnung)
        .equalTo("tenant", tenant)
        .ascending("jahr")
        .include("massnahme")
        // @ts-ignore
        .include("massnahme.kategorie")
        // @ts-ignore
        .include("massnahme.kategorie.kategorie")
        .limit(1000000)
        .find();

      const csvRows = csvWithMeta.split("\r\n");
      // Remove meta information
      csvRows.splice(0, 2);
      const csv = csvRows.join("\r\n");

      const { data: rows } = await parse(csv, {
        header: true,
        delimiter: ";",
        transform: (value, key) => {
          if (this.attributesOfTypeNumber.includes(key)) {
            if (!value) {
              return null;
            }

            const number = parseFloat(value.replace(",", "."));

            if (Number.isNaN(number)) {
              return null;
            } else {
              return number;
            }
          }

          if (this.attributesOfTypeArray.includes(key)) {
            if (!value) {
              return [];
            }

            return value.split(", ");
          }

          if (!value) {
            return null;
          }

          return value;
        },
      });

      const grouped = {};

      for (const row of rows ) {
        if (!grouped[row["massnahme.id"]]) {
          grouped[row["massnahme.id"]] = {
            id: row["massnahme.id"],
            bezeichnung: row["massnahme.bezeichnung"],
            startDatum: new Date(row["massnahme.startDatum"]),
            endDatum: row["massnahme.endDatum"]
              ? new Date(row["massnahme.endDatum"])
              : undefined,
            kategorieId: row["massnahme.kategorie.id"],
            jahre: [],
          };
        }

        grouped[row["massnahme.id"]].jahre.push({
          id: row["id"],
          ...pick(row, this.attributes),
        });
      }

      for (const group of Object.values(grouped)) {
        let massnahme = massnahmen.find((x) => x.id === group.id);

        if (!massnahme) {
          massnahme = new Massnahme();
        }

        const kategorie = unterkategorien.find(
          (x) => x.id === group.kategorieId
        );

        if (kategorie) {
          massnahme.set("kategorie", kategorie);
        } else {
          message.error(
            `Ungültiger Wert '${group.kategorieId}' in der Spalte 'massnahme.kategorie.id' für die Zeile mit 'massnahme.id' = '${group.id}'.`
          );
        }

        massnahme.set("bezeichnung", group.bezeichnung);
        massnahme.set("startDatum", group.startDatum);
        massnahme.set("endDatum", group.endDatum);

        await massnahme.save();

        for (const { id, ...data } of group.jahre) {
          let massnahmenJahr = massnahmenJahre.find((x) => x.id === id);

          if (!massnahmenJahr) {
            massnahmenJahr = new MassnahmeBerechnung();
          }

          await massnahmenJahr.save(data );
        }
      }

      hide();
      message.success("Daten wurden importiert");
    } catch (error) {
      hide();
      message.error("Daten konnten nicht importiert werden.");
      console.error(error);
    }
  }

  async export() {
    const hide = message.loading("Daten werden exportiert...", 0);
    try {
      await translate("klimazaehler:init");

      const tenant = (await this.app.getTenant()) ;

      const massnahmenJahre = await new Parse.Query(MassnahmeBerechnung)
        .equalTo("tenant", tenant)
        .ascending("jahr")
        .include("massnahme")
        // @ts-ignore
        .include("massnahme.kategorie")
        .limit(1000000)
        .find();

      const massnahmenNachweise = await new Parse.Query(MassnahmeNachweis)
        .equalTo("tenant", tenant)
        .limit(1000000)
        .find();

      const data = [];

      data.push(["ASEW.KLIMAZähler Export", "version", "1"]);

      data.push([
        "Kategorie: " +
          translateSync(
            `klimazaehler:classes.KlimaZaehler_UnterKategorie.fields.bezeichnung`
          ),
        "Maßnahme: " +
          translateSync(
            `klimazaehler:classes.KlimaZaehler_Massnahme.fields.bezeichnung`
          ),
        "Maßnahme: " +
          translateSync(
            `klimazaehler:classes.KlimaZaehler_Massnahme.fields.startDatum`
          ),
        "Maßnahme: " +
          translateSync(
            `klimazaehler:classes.KlimaZaehler_Massnahme.fields.endDatum`
          ),
        "Vermeidungsfaktor",
        "Einsparung in tCO₂",
        "Nachweis",
        ...this.attributes.map((key) =>
          translateSync(
            `klimazaehler:classes.KlimaZaehler_MassnahmeBerechnung.fields.${key}`
          )
        ),
        "id",
        "massnahme.id",
        "massnahme.kategorie.id",
      ]);

      data.push([
        "massnahme.kategorie.bezeichnung",
        "massnahme.bezeichnung",
        "massnahme.startDatum",
        "massnahme.endDatum",
        "factor",
        "saving",
        "prove",
        ...this.attributes,
        "id",
        "massnahme.id",
        "massnahme.kategorie.id",
      ]);

      for (const mj of massnahmenJahre) {
        const factor =
          mj.faktor || mj.massnahme.kategorie.faktoren[mj.jahr] || 0;

        const saving = this.app
          .getMeasureYearSaving(mj)
          .toString()
          .replace(".", ",");

        const prove = !!massnahmenNachweise.find(
          (prove) =>
            prove.jahr === mj.jahr && prove.massnahme.id === mj.massnahme.id
        )
          ? "Ja"
          : "Nein";

        data.push([
          mj.massnahme.kategorie.bezeichnung,
          mj.massnahme.bezeichnung,
          mj.massnahme.startDatum.toJSON(),
          _optionalChain([mj, 'access', _ => _.massnahme, 'access', _2 => _2.endDatum, 'optionalAccess', _3 => _3.toJSON, 'call', _4 => _4()]) || "",
          factor,
          saving,
          prove,
          ...this.attributes.map((key) => {
            const value = mj.get(
              key 
            );

            if (this.attributesOfTypeNumber.includes(key)) {
              return _optionalChain([value, 'optionalAccess', _5 => _5.toString, 'call', _6 => _6(), 'optionalAccess', _7 => _7.replace, 'call', _8 => _8(".", ",")]) || "";
            }

            if (
              this.attributesOfTypeArray.includes(key) &&
              Array.isArray(value)
            ) {
              return value.join(", ");
            }

            return _optionalChain([value, 'optionalAccess', _9 => _9.toString, 'call', _10 => _10()]) || "";
          }),
          mj.id,
          mj.massnahme.id,
          mj.massnahme.kategorie.id,
        ]);
      }

      const csv = toCSV(data, {
        delimiter: ";",
      });

      hide();
      message.success("Daten wurden exportiert");
      return csv;
    } catch (error) {
      hide();
      message.error("Daten konnten nicht exportiert werden.");
      console.error(error);
      return "";
    }
  }

   async downloadExport() {
    const csv = await this.export();

    const tenant = await this.app.getTenant();
    const tenantName = _optionalChain([tenant, 'optionalAccess', _11 => _11.get, 'call', _12 => _12("label")]);
    const tenantId = _optionalChain([tenant, 'optionalAccess', _13 => _13.id]);

    const fileName = `asew-klimazaehler-export-${tenantName}-${tenantId}.csv`;

    downloadCSV(fileName, csv);
  }

   async importFile() {
    try {
      const csv = await requestFile();
      await this.import(csv);
    } catch (error) {
      console.error(error);
    }
  }
}

function pick(obj, keys) {
  return Object.fromEntries(
    keys.filter((key) => key in obj).map((key) => [key, obj[key]])
  );
}

function requestFile() {
  return new Promise((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "*.csv";
    input.onchange = (e) => {
      // you can use this method to get file and perform respective operations
      let files = Array.from(input.files);

      if (files[0]) {
        const reader = new FileReader();
        reader.onload = function () {
          resolve(reader.result );
        };

        reader.readAsText(files[0]);
      } else {
        reject(new Error("Es wurde keine Datei ausgewählt"));
      }
    };

    input.click();
  });
}
