import Parse from "parse";





































export class MassnahmeBerechnung extends Parse.Object {
  static __initStatic() {this.className = "KlimaZaehler_MassnahmeBerechnung"}

  constructor(data) {
    super("KlimaZaehler_MassnahmeBerechnung", data );
  }

  get absatz() {
    return super.get("absatz");
  }
  set absatz(value) {
    super.set("absatz", value);
  }
  get absatz_kg() {
    return super.get("absatz_kg");
  }
  set absatz_kg(value) {
    super.set("absatz_kg", value);
  }
  get adresse() {
    return super.get("adresse");
  }
  set adresse(value) {
    super.set("adresse", value);
  }
  get anteilBioerdgas() {
    return super.get("anteilBioerdgas");
  }
  set anteilBioerdgas(value) {
    super.set("anteilBioerdgas", value);
  }
  get anzahl() {
    return super.get("anzahl");
  }
  set anzahl(value) {
    super.set("anzahl", value);
  }
  get brennstoffeinsatz() {
    return super.get("brennstoffeinsatz");
  }
  set brennstoffeinsatz(value) {
    super.set("brennstoffeinsatz", value);
  }
  get defaultFaktor() {
    return super.get("defaultFaktor");
  }
  set defaultFaktor(value) {
    super.set("defaultFaktor", value);
  }
  get emissionsfaktor() {
    return super.get("emissionsfaktor");
  }
  set emissionsfaktor(value) {
    super.set("emissionsfaktor", value);
  }
  get erzeugterStrom() {
    return super.get("erzeugterStrom");
  }
  set erzeugterStrom(value) {
    super.set("erzeugterStrom", value);
  }
  get erzeugteWaerme() {
    return super.get("erzeugteWaerme");
  }
  set erzeugteWaerme(value) {
    super.set("erzeugteWaerme", value);
  }
  get fahrleistung() {
    return super.get("fahrleistung");
  }
  set fahrleistung(value) {
    super.set("fahrleistung", value);
  }
  get faktor() {
    return super.get("faktor");
  }
  set faktor(value) {
    super.set("faktor", value);
  }
  get foerdervolumen() {
    return super.get("foerdervolumen");
  }
  set foerdervolumen(value) {
    super.set("foerdervolumen", value);
  }
  get jahr() {
    return super.get("jahr");
  }
  set jahr(value) {
    super.set("jahr", value);
  }
  get kommentar() {
    return super.get("kommentar");
  }
  set kommentar(value) {
    super.set("kommentar", value);
  }
  get kundenanzahl() {
    return super.get("kundenanzahl");
  }
  set kundenanzahl(value) {
    super.set("kundenanzahl", value);
  }
  get kundengruppe() {
    return super.get("kundengruppe");
  }
  set kundengruppe(value) {
    super.set("kundengruppe", value);
  }
  get massnahme() {
    return super.get("massnahme");
  }
  set massnahme(value) {
    super.set("massnahme", value);
  }
  get menge() {
    return super.get("menge");
  }
  set menge(value) {
    super.set("menge", value);
  }
  get nennleistung() {
    return super.get("nennleistung");
  }
  set nennleistung(value) {
    super.set("nennleistung", value);
  }
  get oekofondAufschlag() {
    return super.get("oekofondAufschlag");
  }
  set oekofondAufschlag(value) {
    super.set("oekofondAufschlag", value);
  }
  get oekofondEinnahmen() {
    return super.get("oekofondEinnahmen");
  }
  set oekofondEinnahmen(value) {
    super.set("oekofondEinnahmen", value);
  }
  get personenkm() {
    return super.get("personenkm");
  }
  set personenkm(value) {
    super.set("personenkm", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} MassnahmeBerechnung.__initStatic();

Parse.Object.registerSubclass("KlimaZaehler_MassnahmeBerechnung", MassnahmeBerechnung);
