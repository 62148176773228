 function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } }import Parse from "parse";




















export class Massnahme extends Parse.Object {
  static __initStatic() {this.className = "KlimaZaehler_Massnahme"}

  constructor(data) {
    super("KlimaZaehler_Massnahme", data );
  }

  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get endDatum() {
    return super.get("endDatum");
  }
  set endDatum(value) {
    super.set("endDatum", value);
  }
  get kategorie() {
    return super.get("kategorie");
  }
  set kategorie(value) {
    super.set("kategorie", value);
  }
  get startDatum() {
    return super.get("startDatum");
  }
  set startDatum(value) {
    super.set("startDatum", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get wiederkehrend() {
    return super.get("wiederkehrend");
  }
  set wiederkehrend(value) {
    super.set("wiederkehrend", value);
  }
  get considerForCalc() {
    return _nullishCoalesce(super.get("considerForCalc"), () => ( true));
  }
  set considerForCalc(value) {
    super.set("considerForCalc", value);
  }
} Massnahme.__initStatic();

Parse.Object.registerSubclass("KlimaZaehler_Massnahme", Massnahme);
