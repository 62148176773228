const _jsxFileName = "/home/runner/work/asew-klimazaehler-admin/asew-klimazaehler-admin/src/js/klimazaehler/components/MeasureOverview.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
import { AdminLayout } from "@opendash/core";
import { translateSync } from "@opendash/i18n";
import { Icon } from "@opendash/icons";
import { $parse, Parse } from "@opendash/plugin-parse";
import { createComponent, makeAutoObservable } from "@opendash/state";
import { AdminToolbar } from "@opendash/ui";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Select,
  Space,
  Statistic,
  Table,
  Tag,
} from "antd";

import { runInAction } from "mobx";

import React, { } from "react";
import { useNavigate } from "react-router-dom";
import { $state } from "../state";
import {
  Kategorie,
  Massnahme,
  MassnahmeBerechnung,
  MassnahmeNachweis,
  UnterKategorie,
} from "../types-generated";
import JSZip, { } from "jszip";
import saveAs from "file-saver";

import CollapseNachweise from "./CollapseNachweise";













export class MeasureOverviewState {
   __init() {this.year = $state.lastYear} //$state.currentYear;
   __init2() {this.filter =
    _optionalChain([window, 'access', _6 => _6.localStorage
, 'access', _7 => _7.getItem, 'call', _8 => _8("awew:klimazaehler:measure-overview:filter")
, 'optionalAccess', _9 => _9.split, 'call', _10 => _10(",")]) || []}
   __init3() {this.create = false}

   __init4() {this.oberKategorien = []}
   __init5() {this.unterKategorien = []}
   __init6() {this.massnahmen = []}
   __init7() {this.massnahmenJahre = []}
   __init8() {this.massnahmenNachweise = []}

  constructor() {;MeasureOverviewState.prototype.__init.call(this);MeasureOverviewState.prototype.__init2.call(this);MeasureOverviewState.prototype.__init3.call(this);MeasureOverviewState.prototype.__init4.call(this);MeasureOverviewState.prototype.__init5.call(this);MeasureOverviewState.prototype.__init6.call(this);MeasureOverviewState.prototype.__init7.call(this);MeasureOverviewState.prototype.__init8.call(this);
    makeAutoObservable(this);

    const yearls = parseInt(
      localStorage.getItem("awew:klimazaehler:current-year") || ""
    );

    if (!Number.isNaN(yearls)) {
      this.year = yearls;
    }

    this.init();
  }

  // meine Logik-CM
  async downloadNachweis(nachweis) {
    const datei = await nachweis.get("datei").getData();
    const bezeichnung = nachweis.get("massnahme").get("bezeichnung");
    const mimeTypeMapping = {
      ".pdf": "application/pdf",
      ".png": "image/png",
      ".jpg": "image/jpeg",
      ".jpeg": "image/jpeg",
      ".gif": "image/gif",
      ".txt": "text/plain",
      ".csv": "text/csv",
      ".json": "application/json",
      ".zip": "application/zip",
      // add more mappings as needed
    };
    function getMimeTypeFromExtension(filename) {
      const extension = filename
        .substring(filename.lastIndexOf("."))
        .toLowerCase();
      // @ts-expect-error
      return mimeTypeMapping[extension] || "application/octet-stream";
    }

    const b64toBlob = (
      base64,
      type = "application/pdf" //auch jpeg muss hier umgewandelt werden
    ) => fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

    const file = await b64toBlob(
      datei,
      getMimeTypeFromExtension(nachweis.datei.name())
    );

    if (this.year === 0) {
      saveAs(file, `${bezeichnung}_${nachweis.jahr}`);
    } else {
      saveAs(file, `${bezeichnung}_${this.year}`);
    }
  }
  //----------------------------------------

  setFilter(filter, checked) {
    if (checked) {
      this.filter = this.filter.filter((f) => f !== filter);
    } else {
      this.filter.push(filter);
    }

    window.localStorage.setItem(
      "awew:klimazaehler:measure-overview:filter",
      this.filter.join(",")
    );
  }

   async init() {
    const tenant = (await $state.getTenant()) ;

    const ok = await new Parse.Query(Kategorie)
      .limit(1000000)
      .ascending("order")
      .find();
    const uk = await new Parse.Query(UnterKategorie)
      .include("kategorie")
      .limit(1000000)
      .find();
    const m = await new Parse.Query(Massnahme)
      .equalTo("tenant", tenant)
      .ascending("bezeichnung")
      .limit(1000000)
      .find();
    const mb = await new Parse.Query(MassnahmeBerechnung)
      .equalTo("tenant", tenant)
      .ascending("jahr")
      .include("massnahme")
      .limit(1000000)
      .find();
    const mn = await new Parse.Query(MassnahmeNachweis)
      .equalTo("tenant", tenant)
      .limit(1000000)
      .find();

    runInAction(() => {
      this.oberKategorien = ok;
      this.unterKategorien = uk;
      this.massnahmen = m;
      this.massnahmenJahre = mb;
      this.massnahmenNachweise = mn;
    });
  }

  get availableCategories() {
    let measures = this.massnahmen;

    if ($state.tenantFilter) {
      measures = measures.filter((m) => _optionalChain([m, 'access', _11 => _11.tenant, 'optionalAccess', _12 => _12.id]) === $state.tenantFilter);
    }

    if (this.year) {
      measures = measures.filter(
        (m) =>
          (_optionalChain([m, 'access', _13 => _13.startDatum, 'optionalAccess', _14 => _14.getFullYear, 'call', _15 => _15()]) || 0) <= this.year &&
          (_optionalChain([m, 'access', _16 => _16.endDatum, 'optionalAccess', _17 => _17.getFullYear, 'call', _18 => _18()]) || 9999) >= this.year
      );
    }

    const ids = measures.map((m) => m.kategorie.id);

    let result = this.unterKategorien;

    result = result.filter((k) => ids.includes(k.id));

    result = result.filter((k) => !this.filter.includes(k.kategorie.id));

    result.sort((a, b) => {
      let x = 0;
      x = a.kategorie.bezeichnung.localeCompare(b.kategorie.bezeichnung);
      if (x !== 0) return x;
      x = a.bezeichnung.localeCompare(b.bezeichnung);
      return x;
    });

    result.sort(
      createMultiSortFunction([
        (a, b) => a.kategorie.order - b.kategorie.order,
        (a, b) => a.bezeichnung.localeCompare(b.bezeichnung),
      ])
    );

    return result;
  }

  getRows(category) {
    if (!this.year) {
      const allRecords = this.massnahmenJahre
        .filter((mj) => mj.massnahme.kategorie.id === category.id)
        .map((mj) => {
          const m = mj.massnahme;

          return {
            id: mj.id,
            mid: m.id,
            label: m.bezeichnung,
            factor: mj.faktor
              ? new Intl.NumberFormat("de-DE", {
                  useGrouping: true,
                  minimumFractionDigits: 4,
                }).format(mj.faktor)
              : m.kategorie.faktoren[mj.jahr]
              ? new Intl.NumberFormat("de-DE", {
                  useGrouping: true,
                  minimumFractionDigits: 4,
                }).format(m.kategorie.faktoren[mj.jahr])
              : 0,
            customFactor: !!mj.faktor,
            savingNumber: $state.getMeasureYearSaving(mj),
            saving:
              new Intl.NumberFormat("de-DE", {
                useGrouping: true,
                minimumFractionDigits: 4,
              }).format($state.getMeasureYearSaving(mj)) + " t CO₂",
            prove: !!this.massnahmenNachweise.find(
              (prove) => prove.jahr === mj.jahr && prove.massnahme.id === m.id
            ),
            year: mj.jahr || this.year,
            fields: Object.fromEntries(
              category.felder.map((feld) => [
                feld,
                typeof _optionalChain([mj, 'optionalAccess', _19 => _19.get, 'call', _20 => _20(feld)]) === "number"
                  ? new Intl.NumberFormat("de-DE", {
                      useGrouping: true,
                    }).format(_optionalChain([mj, 'optionalAccess', _21 => _21.get, 'call', _22 => _22(feld)]))
                  : _optionalChain([mj, 'optionalAccess', _23 => _23.get, 'call', _24 => _24(feld)]) || "-",
              ])
            ),
          };
        });

      ///-------------------------------------------------------------------------------------------------------

      const allRecordsSum = this.massnahmenJahre
        .filter((mj) => mj.massnahme.kategorie.id === category.id)
        .map((mj) => {
          const m = mj.massnahme;

          return {
            id: m.id,
            mid: m.id,
            label: m.bezeichnung,
            factor: _optionalChain([mj, 'optionalAccess', _25 => _25.faktor])
              ? new Intl.NumberFormat("de-DE", {
                  useGrouping: true,
                  minimumFractionDigits: 4,
                }).format(mj.faktor)
              : m.kategorie.faktoren[_optionalChain([mj, 'optionalAccess', _26 => _26.jahr]) || this.year]
              ? new Intl.NumberFormat("de-DE", {
                  useGrouping: true,
                  minimumFractionDigits: 4,
                }).format(m.kategorie.faktoren[_optionalChain([mj, 'optionalAccess', _27 => _27.jahr]) || this.year])
              : 0,
            customFactor: !!_optionalChain([mj, 'optionalAccess', _28 => _28.faktor]),
            savingNumber: $state.getMeasureYearSaving(mj),
            saving:
              new Intl.NumberFormat("de-DE", {
                useGrouping: true,
                minimumFractionDigits: 4,
              }).format($state.getMeasureYearSaving(mj)) + " t CO₂",
            prove: !!this.massnahmenNachweise.find(
              (prove) => prove.jahr === _optionalChain([mj, 'optionalAccess', _29 => _29.jahr]) && prove.massnahme.id === m.id
            ),
            year: _optionalChain([mj, 'optionalAccess', _30 => _30.jahr]) || this.year,
            fields: Object.fromEntries(
              category.felder.map((feld) => [
                feld,
                typeof _optionalChain([mj, 'optionalAccess', _31 => _31.get, 'call', _32 => _32(feld)]) === "number"
                  ? new Intl.NumberFormat("de-DE", {
                      useGrouping: true,
                    }).format(_optionalChain([mj, 'optionalAccess', _33 => _33.get, 'call', _34 => _34(feld)]))
                  : _optionalChain([mj, 'optionalAccess', _35 => _35.get, 'call', _36 => _36(feld)]) || "-",
              ])
            ),
          };
        });

      const totalSaving = parseFloat(
        allRecordsSum
          .reduce((sum, measure) => sum + parseFloat(measure.savingNumber), 0)
          .toFixed(3)
      );

      // Erstellen der Summenzeile //selbe Struktur verwenden! Nur was relevant ist verwenden
      const sumRow = {
        id: "sum",
        mid: "",
        label: React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 314}}, "Summe"),
        factor: "", //nicht relevant
        customFactor: false, //nicht relevant
        saving: (
          React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 318}}
            , new Intl.NumberFormat("de-DE", {
              useGrouping: true,
            }).format(totalSaving), " ", "t CO₂"

          )
        ),
        prove: false, //nicht relevant
        year: "",
        fields: Object.fromEntries(
          category.felder.map((feld) => {
            console.log("FELD", feld);
            // bilde die summe von allen measures
            let sum = 0;
            console.log("ALLRECORDSSUM", allRecordsSum);
            allRecordsSum.forEach((measure) => {
              /*
              const mj = this.massnahmenJahre.find(
                (mj) => mj.massnahme.id === measure.id
              );

              const fieldValue = mj?.get(feld);
              console.log("Fieldvalue", fieldValue);
              //if (fieldValue) sum += parseFloat(fieldValue);
              */

              if (measure.fields) {
                const fieldValue2 = measure.fields[feld];
                console.log("VALUE", fieldValue2);
                if (fieldValue2 && !Number.isNaN(parseFloat(fieldValue2)))
                  sum += parseFloat(fieldValue2.replaceAll(".", ""));
                console.log(sum);
              }
            });
            return [
              feld,
              !!sum ? (
                React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 355}}
                  , new Intl.NumberFormat("de-DE", {
                    useGrouping: true,
                  }).format(sum)
                )
              ) : (
                ""
              ),
            ];
          })
        ),
      };
      ///-------------------------------------------------------------------------------------------------------

      // Hinzufügen der Summenzeile zu allMeasures
      allRecords.push(sumRow );

      return allRecords ;
    }

    const measures = this.massnahmen.filter(
      (m) =>
        m.kategorie.id === category.id &&
        (_optionalChain([m, 'access', _37 => _37.startDatum, 'optionalAccess', _38 => _38.getFullYear, 'call', _39 => _39()]) || 0) <= this.year &&
        (_optionalChain([m, 'access', _40 => _40.endDatum, 'optionalAccess', _41 => _41.getFullYear, 'call', _42 => _42()]) || 9999) >= this.year
    );

    const allMeasures = measures.map((m) => {
      const mj = this.massnahmenJahre.find(
        (mj) => mj.massnahme.id === m.id && mj.jahr === this.year
      );

      return {
        id: m.id,
        mid: m.id,
        label: m.bezeichnung,
        factor: _optionalChain([mj, 'optionalAccess', _43 => _43.faktor])
          ? new Intl.NumberFormat("de-DE", {
              useGrouping: true,
              minimumFractionDigits: 4,
            }).format(mj.faktor)
          : m.kategorie.faktoren[_optionalChain([mj, 'optionalAccess', _44 => _44.jahr]) || this.year]
          ? new Intl.NumberFormat("de-DE", {
              useGrouping: true,
              minimumFractionDigits: 4,
            }).format(m.kategorie.faktoren[_optionalChain([mj, 'optionalAccess', _45 => _45.jahr]) || this.year])
          : 0,
        customFactor: !!_optionalChain([mj, 'optionalAccess', _46 => _46.faktor]),
        savingNumber: $state.getMeasureYearSaving(mj),
        saving:
          new Intl.NumberFormat("de-DE", {
            useGrouping: true,
            minimumFractionDigits: 4,
          }).format($state.getMeasureYearSaving(mj)) + " t CO₂",
        prove: !!this.massnahmenNachweise.find(
          (prove) => prove.jahr === _optionalChain([mj, 'optionalAccess', _47 => _47.jahr]) && prove.massnahme.id === m.id
        ),
        year: _optionalChain([mj, 'optionalAccess', _48 => _48.jahr]) || this.year,
        fields: Object.fromEntries(
          category.felder.map((feld) => [
            feld,
            typeof _optionalChain([mj, 'optionalAccess', _49 => _49.get, 'call', _50 => _50(feld)]) === "number"
              ? new Intl.NumberFormat("de-DE", {
                  useGrouping: true,
                }).format(_optionalChain([mj, 'optionalAccess', _51 => _51.get, 'call', _52 => _52(feld)]))
              : _optionalChain([mj, 'optionalAccess', _53 => _53.get, 'call', _54 => _54(feld)]) || "-",
          ])
        ),
      };
    });

    // co2 summe vorhanden, der Rest fehlt noch, muss noch die Summe von den Zeilen abheben
    // Berechnungen der Summen // parseFloat wandelt strings in numbers um
    const totalSaving = parseFloat(
      allMeasures
        .reduce((sum, measure) => sum + parseFloat(measure.savingNumber), 0)
        .toFixed(3)
    );

    // Erstellen der Summenzeile //selbe Struktur verwenden! Nur was relevant ist verwenden
    const sumRow = {
      id: "sum",
      mid: "",
      label: React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 438}}, "Summe"),
      factor: "", //nicht relevant
      customFactor: false, //nicht relevant
      saving: (
        React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 442}}
          , new Intl.NumberFormat("de-DE", {
            useGrouping: true,
          }).format(totalSaving), " ", "t CO₂"

        )
      ),
      prove: false, //nicht relevant
      year: this.year,
      fields: Object.fromEntries(
        category.felder.map((feld) => {
          // bilde die summe von allen measures
          let sum = 0;
          allMeasures.forEach((measure) => {
            const mj = this.massnahmenJahre.find(
              (mj) => mj.massnahme.id === measure.id && mj.jahr === this.year
            );

            const fieldValue = _optionalChain([mj, 'optionalAccess', _55 => _55.get, 'call', _56 => _56(feld)]);
            if (fieldValue) sum += parseFloat(fieldValue);
          });
          return [
            feld,
            !!sum ? (
              React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 466}}
                , new Intl.NumberFormat("de-DE", {
                  useGrouping: true,
                }).format(sum)
              )
            ) : (
              ""
            ),
          ];
        })
      ),
    };

    const allRecords = allMeasures ;

    // Hinzufügen der Summenzeile zu allMeasures
    allRecords.push(sumRow);

    return allRecords;
  }
  //---------------------------------------------------------------------------------------------------
  getColumns(
    category,
    navigate
  ) {
    return [
      {
        key: "label",
        title: "Bezeichnung",
        dataIndex: "label",
        width: 200,
        render: (label, { mid }) => {
          return (
            React.createElement('span', {
              style: { cursor: "pointer" },
              onClick: () => {
                // this.editMeasure(id);
                navigate(`/asew/klimazaehler/massnahmen/update/${mid}`);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 499}}

              , label
            )
          );
        },
      },

      ...(this.year
        ? []
        : [
            {
              key: "year",
              title: "Jahr",
              dataIndex: "year",
            },
          ]),

      ...category.felderKurz.map((field) => {
        return {
          key: "field-" + field,
          title: translateSync(
            "klimazaehler:classes.KlimaZaehler_MassnahmeBerechnung.fields." +
              field
          ),
          dataIndex: ["fields", field],
          render: (value, test, bla) => {
            if (Array.isArray(value)) {
              return value.join(", ");
            }

            return typeof value == "number"
              ? new Intl.NumberFormat("de-DE", {
                  useGrouping: true,
                }).format(value)
              : value;
          },
        };
      }),

      {
        key: "faktor",
        title: "Vermeidungsfaktor",
        dataIndex: "factor",
        width: 70,
        render: (factor, { customFactor }) => {
          return (
            React.createElement('span', {
              style: 
                customFactor ? { color: "var(--ant-success-color-active)" } : {}
              ,
              title: 
                customFactor
                  ? translateSync(
                      "klimazaehler:measures.default_factor_overwritten"
                    )
                  : translateSync("klimazaehler:measures.default_factor")
              ,
              children: factor, __self: this, __source: {fileName: _jsxFileName, lineNumber: 551}}
            )
          );
        },
      },
      {
        key: "saving",
        title: "Einsparung",
        dataIndex: "saving",
        width: 140,
      },
      {
        key: "prove",
        align: "center",
        title: "Nachweis",
        dataIndex: "prove",
        width: 86,
        render: (v) => {
          return v ? "✓" : "";
        },
      },
      {
        key: "mid",
        align: "center",
        title: "Aktionen",
        dataIndex: "mid",
        width: 82,
        render: (id) => {
          return id ? (
            React.createElement(Icon, {
              icon: "fa:pen",
              style: { cursor: "pointer" },
              onClick: () => {
                // this.editMeasure(id);
                navigate(`/asew/klimazaehler/massnahmen/update/${id}`);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 591}}
            )
          ) : null;
        },
      },
    ];
  }

  setYear(year) {
    this.year = year;
    localStorage.setItem("awew:klimazaehler:current-year", year.toString());
  }

  async createMeasure(data




) {
    const obj = new Massnahme({
      kategorie: this.unterKategorien.find((o) => o.id === data.kategorie),
      bezeichnung: data.bezeichnung,
      startDatum: data.startDatum.startOf("month").toDate(),
      endDatum: _optionalChain([data, 'access', _57 => _57.endDatum, 'optionalAccess', _58 => _58.startOf, 'call', _59 => _59("month"), 'access', _60 => _60.toDate, 'call', _61 => _61()]),
    });

    await obj.save();

    for (
      let y = data.startDatum.year();
      y <= (_optionalChain([data, 'access', _62 => _62.endDatum, 'optionalAccess', _63 => _63.year, 'call', _64 => _64()]) || new Date().getFullYear());
      y++
    ) {
      const mb = new MassnahmeBerechnung({
        massnahme: obj,
        jahr: y,
      });

      await mb.save();
    }

    return obj.id;
  }

  editMeasure(id) {
    // TODO
  }

  deleteMeasures(category) {
    const x = this.massnahmen.filter((m) => m.kategorie.id === category.id);
    const ids = x.map((m) => m.id);

    $parse.ui.deleteObject(Massnahme.className, ids).then(() => {
      this.init();
    });
  }

  async import() {
    await $state.importExportService.importFile();
    await $state.fetchOutputs();
    await this.init();
  }

  async export() {
    await $state.importExportService.downloadExport();
  }

  async nachweiseObjects(year) {
    if (year != 0) {
      const nachweise = await new Parse.Query(MassnahmeNachweis)
        .equalTo("jahr", this.year)
        .include("massnahme")
        .find();
      return nachweise;
    } else {
      const nachweise = await new Parse.Query(MassnahmeNachweis)
        .include("massnahme")
        .find();
      return nachweise;
    }
  }

  //hier Download alle Nachweise -CM
  //

  async downloadNachweise() {
    let nachweise = await new Parse.Query(MassnahmeNachweis)

      .include("massnahme")
      .find();

    if (this.year != 0) {
      nachweise = await new Parse.Query(MassnahmeNachweis)
        .equalTo("jahr", this.year)
        .include("massnahme")
        .find();
    }

    const zip = new JSZip();
    const filesData = nachweise.map((nachweis) => {
      const datei = nachweis.get("datei"); //
      const dateiName = nachweis.get("massnahme").get("bezeichnung");
      const extension = datei
        .name()
        .substring(datei.name().lastIndexOf("."))
        .toLowerCase();

      return {
        file: datei, // die Datei selbst
        name: `${dateiName}${extension}`, //name of file and file extension
      };
    });

    for (const fileData of filesData) {
      const fileDataString = await fileData.file.getData(); //getData() ist der Inhalt der Datei
      zip.file(fileData.name, fileDataString, { base64: true });
    }

    // Generate the zip file
    const zipData = await zip.generateAsync({ type: "blob" });

    // Save the zip file
    saveAs(zipData, this.year == 0 ? "Nachweise" : `Nachweise ${this.year}`);
  }

  //---------------------------------------------------------------------------------
  /*async downloadNachweise() {
    const nachweise = await this.nachweiseObjects(this.year);

    console.log("Retrieved nachweise:", nachweise);
    //Nachweise werden benötigt um zu testen, Code muss noch überarbeitet werden
    const zip = new JSZip();
    const filesData = nachweise.map((nachweis) => ({
      file: nachweis.get("datei"),
      name: nachweis.get("massnahme").get("bezeichnung"),
    }));

    // Iterate over the files and add them to the zip
    for (const fileData of filesData) {
      const fileDataString = await fileData.file.getData();
      zip.file(fileData.name, fileDataString, { base64: true });
    }

    // Generate the zip file
    const zipData = await zip.generateAsync({ type: "blob" });

    // Save the zip file
    saveAs(zipData, this.year == 0 ? "" : `Nachweise ${this.year}`);
  }*/
}

export const MeasureOverview = createComponent(function MeasureOverview() {
  const navigate = useNavigate();

  const state = React.useMemo(() => new MeasureOverviewState(), []);

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 754}}
      , React.createElement(AdminToolbar, {
        title: "Maßnahmen",
        actions: [
          // <Button children="Exportieren" onClick={() => state.export()} />,
          React.createElement(Button, {
            type: "primary",
            children: "Neue Maßnahme anlegen"  ,
            disabled: 
              !$parse.ui.getClassPermission(Massnahme.className, "create")
            ,
            onClick: () => {
              runInAction(() => {
                state.create = true;
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 759}}
          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 755}}

        , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 773}}
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 774}}, "Jahr:")
          , React.createElement(Select, {
            size: "small",
            value: state.year,
            onChange: (v) => {
              state.setYear(v);
            },
            options: [
              {
                label: "Alle Jahre",
                value: 0,
              },
              ...$state.years.map((year) => ({
                label: year,
                value: year,
              })),
            ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 775}}
          )
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 792}}, "Kategorien:")
          /* <Tag.CheckableTag
              children="Alle"
              style={{
                display: "inline-block",
                background: "#424242",
                color: "white",
              }}
              onClick={() => {
                runInAction(() => {
                  state.filter = null;
                });
              }}
            /> */
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 806}}
            , state.oberKategorien.map((k) => (
              React.createElement(Tag.CheckableTag, {
                key: k.id,
                children: k.bezeichnung,
                style: 
                  !state.filter.includes(k.id)
                    ? {
                        backgroundColor: k.farbe,
                        color: "black",
                      }
                    : {}
                ,
                checked: !state.filter.includes(k.id),
                onChange: (checked) => {
                  state.setFilter(k.id, checked);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 808}}
              )
            ))
          )
        )
      )
      , React.createElement('div', { style: { width: "100%", backgroundColor: "white", padding: "24px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 828}}
        , React.createElement(List, {
          dataSource: state.availableCategories,
          rowKey: (row) => row.id,
          renderItem: (category) => (
            React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 833}}
              , React.createElement('div', {
                style: {
                  // borderLeft: `5px solid ${category.kategorie.farbe}`,
                  background: category.kategorie.farbe,
                  borderBottom: "2px solid rgba(0,0,0,.2)",
                  padding: 10,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 834}}

                , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 842}}
                  , category.bezeichnung, " - "  , category.kategorie.bezeichnung
                )
                , React.createElement('span', {
                  style: { float: "right", cursor: "pointer" },
                  onClick: () => {
                    state.deleteMeasures(category);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 845}}

                  , React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 851}} )
                )
              )

              , React.createElement(Table, {
                size: "small",
                rowKey: (row) => row.id,
                columns: state.getColumns(category, navigate),
                dataSource: state.getRows(category),
                pagination: false,
                onRow: (row) => {
                  if (row.id !== "sum") return row;
                  return {
                    ...row,
                    style: { background: "#ededed" },
                  };
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 855}}
              )

              , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 870}} )
            )
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 829}}
        )

        , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 875}}
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 876}} )
          , React.createElement(Space, { size: 40, __self: this, __source: {fileName: _jsxFileName, lineNumber: 877}}
            , (state.year || null) && (
              React.createElement(Statistic, {
                title: "Jahreseinsparung inkl. Hochrechnung"  ,
                value: $state.getYearSaving(state.year),
                suffix: "t CO₂" ,
                precision: 2,
                groupSeparator: ".",
                decimalSeparator: ",", __self: this, __source: {fileName: _jsxFileName, lineNumber: 879}}
              )
            )
            , React.createElement(Statistic, {
              title: "Gesamteinsparung inkl. Hochrechnung"  ,
              value: $state.getTotalSaving(),
              suffix: "t CO₂" ,
              precision: 2,
              groupSeparator: ".",
              decimalSeparator: ",", __self: this, __source: {fileName: _jsxFileName, lineNumber: 888}}
            )
          )
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 897}} )
          , React.createElement('div', { style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 898}}
            , React.createElement(CollapseNachweise, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 899}} )
          )
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 901}} )
          , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 902}}
            , React.createElement(Button, {
              children: "Daten Exportieren" ,
              onClick: () => state.export(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 903}}
            )
            , React.createElement(Button, {
              children: "Daten Importieren" ,
              onClick: () => state.import(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 907}}
            )
            , React.createElement(Button, {
              children: "Alle Nachweise Herunterladen"  , // Button herunterladen -CM -------------------------------
              onClick: () => state.downloadNachweise(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 911}}
            )
          )
        )
        , React.createElement(MeasureCreate, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 917}} )
      )
    )
  );
});

const MeasureCreate = createComponent(
  function MeasureCreate({ state }) {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [parentCategory, setParentCategory] = React.useState();

    return (
      React.createElement(Modal, {
        open: state.create,
        title: "Neue Maßnahme anlegen"  ,
        okText: "Weiter",
        onOk: () => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();

              return state.createMeasure(values);
            })
            .then((id) => {
              navigate(`/asew/klimazaehler/massnahmen/update/${id}`);
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        },
        okButtonProps: { disabled: !parentCategory },
        onCancel: () => {
          runInAction(() => {
            state.create = false;
          });
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 931}}

        , React.createElement(Form, {
          form: form,
          layout: "vertical",
          name: "form_in_modal",
          initialValues: { modifier: "public" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 957}}

          , React.createElement(Form.Item, { label: "Oberkategorie", __self: this, __source: {fileName: _jsxFileName, lineNumber: 963}}
            , React.createElement(Select, {
              placeholder: "Kategorie wählen" ,
              value: parentCategory,
              onChange: (id) => {
                setParentCategory(id);
              },
              options: state.oberKategorien
                .map((obj) => ({
                  label: obj.bezeichnung,
                  value: obj.id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)), __self: this, __source: {fileName: _jsxFileName, lineNumber: 964}}
            )
          )
          , parentCategory && (
            React.createElement(React.Fragment, null
              , React.createElement(Form.Item, {
                name: "kategorie",
                label: "Unterkategorie",
                rules: [
                  {
                    required: true,
                    message: "Bitte wählen Sie eine Unterkategorie",
                  },
                ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 980}}

                , React.createElement(Select, {
                  // value={parentCategory}
                  // onChange={(id) => {
                  //   setParentCategory(id);
                  // }}
                  placeholder: "Kategorie wählen" ,
                  options: state.unterKategorien
                    .filter((obj) => obj.kategorie.id === parentCategory)
                    .map((obj) => ({
                      label: obj.bezeichnung,
                      value: obj.id,
                    }))
                    .sort((a, b) => {
                      if (a.label.startsWith("_")) return 1;
                      if (b.label.startsWith("_")) return -1;
                      return a.label.localeCompare(b.label);
                    }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 990}}
                )
              )

              , React.createElement(Form.Item, {
                name: "bezeichnung",
                label: "Bezeichnung",
                rules: [
                  {
                    required: true,
                    message: "Bitte geben Sie der Maßnahme eine Bezeichnung",
                  },
                ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 1010}}

                , React.createElement(Input, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 1020}} )
              )

              , React.createElement(Form.Item, {
                name: "startDatum",
                label: "Startdatum",
                rules: [
                  {
                    required: true,
                    message: "Bitte wählen Sie ein Startdatum",
                  },
                ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 1023}}

                , React.createElement(DatePicker, { style: { width: "100%" }, picker: "month", __self: this, __source: {fileName: _jsxFileName, lineNumber: 1033}} )
              )

              , React.createElement(Form.Item, {
                name: "endDatum",
                label: "Enddatum",
                help: "Frei lassen, falls kein Enddatum gewünscht ist"      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 1036}}

                , React.createElement(DatePicker, { style: { width: "100%" }, picker: "month", __self: this, __source: {fileName: _jsxFileName, lineNumber: 1041}} )
              )
            )
          )
        )
      )
    );
  }
);

function createMultiSortFunction(
  fns
) {
  return (a, b) => {
    for (const fn of fns) {
      const v = fn(a, b);

      if (v === 0) {
        continue;
      }

      return v;
    }

    return 0;
  };
}
