import Parse from "parse";

















export class MassnahmeNachweis extends Parse.Object {
  static __initStatic() {this.className = "KlimaZaehler_MassnahmeNachweis"}

  constructor(data) {
    super("KlimaZaehler_MassnahmeNachweis", data );
  }

  get datei() {
    return super.get("datei");
  }
  set datei(value) {
    super.set("datei", value);
  }
  get jahr() {
    return super.get("jahr");
  }
  set jahr(value) {
    super.set("jahr", value);
  }
  get massnahme() {
    return super.get("massnahme");
  }
  set massnahme(value) {
    super.set("massnahme", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} MassnahmeNachweis.__initStatic();

Parse.Object.registerSubclass("KlimaZaehler_MassnahmeNachweis", MassnahmeNachweis);
