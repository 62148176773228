const _jsxFileName = "/home/runner/work/asew-klimazaehler-admin/asew-klimazaehler-admin/src/js/klimazaehler/components/MeasureUpdate.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
import { $framework, AdminLayout } from "@opendash/core";
import { translateSync } from "@opendash/i18n";
import { Icon } from "@opendash/icons";
import { $parse, Parse } from "@opendash/plugin-parse";
import { createComponent, makeAutoObservable } from "@opendash/state";
import { AdminToolbar } from "@opendash/ui";
import {
  Button,
  Divider,
  List,
  Space,
  message,
  Statistic,
  Table,
  Checkbox,
  Typography,
} from "antd";


import { runInAction } from "mobx";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { $state } from "../state";
import {
  Massnahme,
  MassnahmeBerechnung,
  MassnahmeNachweis,
} from "../types-generated";












class MeasureUpdateState {
   __init() {this.massnahme = null}
   __init2() {this.massnahmenJahre = []}
   __init3() {this.massnahmenNachweise = []}

  constructor(id) {;MeasureUpdateState.prototype.__init.call(this);MeasureUpdateState.prototype.__init2.call(this);MeasureUpdateState.prototype.__init3.call(this);MeasureUpdateState.prototype.__init4.call(this);
    makeAutoObservable(this);

    this.init(id);
  }

   async init(id) {
    const m = await new Parse.Query(Massnahme).include("kategorie").get(id);

    const mb = await new Parse.Query(MassnahmeBerechnung)
      .equalTo("massnahme", m)
      .ascending("jahr")
      .find();

    const prove = await new Parse.Query(MassnahmeNachweis)
      .equalTo("massnahme", m)
      .ascending("jahr")
      .find();

    await $state.fetchOutputs();

    runInAction(() => {
      this.massnahme = m;
      this.massnahmenJahre = mb;
      this.massnahmenNachweise = prove;
    });

    if (this.missingYears.length > 0) {
      this.createMissingYears();
    }
  }

  get missingYears() {
    const existingYears = this.massnahmenJahre.map((mj) => mj.jahr);

    return $state.years
      .filter((y) => !existingYears.includes(y))
      .reverse()
      .filter((y) => {
        if (this.massnahme) {
          if (this.massnahme.startDatum.getFullYear() > y) {
            return false;
          }

          if (
            this.massnahme.endDatum &&
            this.massnahme.endDatum.getFullYear() < y
          ) {
            return false;
          }
        }

        return true;
      });
  }

  get rows() {
    return this.massnahmenJahre.map((mj) => {
      const m = mj.massnahme;
      const category = m.kategorie;

      return {
        id: mj.id,
        label: m.bezeichnung,

        factor: _optionalChain([mj, 'optionalAccess', _ => _.faktor])
          ? new Intl.NumberFormat("de-DE", {
              useGrouping: true,
              minimumFractionDigits: 4,
            }).format(mj.faktor)
          : m.kategorie.faktoren[mj.jahr]
          ? new Intl.NumberFormat("de-DE", {
              useGrouping: true,
              minimumFractionDigits: 4,
            }).format(m.kategorie.faktoren[mj.jahr])
          : 0,
        customFactor: !!mj.faktor,
        savingNumber: $state.getMeasureYearSaving(mj),
        saving:
          new Intl.NumberFormat("de-DE", {
            useGrouping: true,
            minimumFractionDigits: 4,
          }).format($state.getMeasureYearSaving(mj)) + " t CO₂",
        prove: !!this.massnahmenNachweise.find(
          (prove) => prove.jahr === mj.jahr
        ),
        year: mj.jahr,
        comment:
          mj.kommentar && _optionalChain([mj, 'access', _2 => _2.kommentar, 'optionalAccess', _3 => _3.length]) > 20
            ? mj.kommentar.slice(0, 20) + "..."
            : mj.kommentar,
        fields: Object.fromEntries(
          category.felder.map((feld) => [
            feld,
            typeof _optionalChain([mj, 'optionalAccess', _4 => _4.get, 'call', _5 => _5(feld)]) === "number"
              ? new Intl.NumberFormat("de-DE", {
                  useGrouping: true,
                }).format(_optionalChain([mj, 'optionalAccess', _6 => _6.get, 'call', _7 => _7(feld)]))
              : _optionalChain([mj, 'optionalAccess', _8 => _8.get, 'call', _9 => _9(feld)]) || "-",
          ])
        ),
      };
    });
  }

  get columns() {
    if (!this.massnahme) {
      return [];
    }

    return [
      {
        key: "year",
        title: "Jahr",
        dataIndex: "year",
      },
      ...this.massnahme.kategorie.felder.map((field) => {
        return {
          key: "field-" + field,
          title: translateSync(
            "klimazaehler:classes.KlimaZaehler_MassnahmeBerechnung.fields." +
              field
          ),
          dataIndex: ["fields", field],
          render: (value) => {
            if (Array.isArray(value)) {
              return value.join(", ");
            }

            return value;
          },
        };
      }),

      {
        key: "faktor",
        title: "Vermeidungsfaktor",
        dataIndex: "factor",
        render: (factor, { customFactor }) => {
          return (
            React.createElement('span', {
              style: 
                customFactor ? { color: "var(--ant-success-color-active)" } : {}
              ,
              title: 
                customFactor
                  ? translateSync(
                      "klimazaehler:measures.default_factor_overwritten"
                    )
                  : translateSync("klimazaehler:measures.default_factor")
              ,
              children: factor, __self: this, __source: {fileName: _jsxFileName, lineNumber: 187}}
            )
          );
        },
      },
      {
        key: "saving",
        title: "Einsparung",
        dataIndex: "saving",
      },
      {
        key: "comment",
        title: "Kommentar",
        dataIndex: "comment",
      },
      {
        key: "prove",
        title: "Nachweis",
        dataIndex: "prove",
        render: (v) => {
          return v ? "✓" : "-";
        },
      },
      {
        key: "id",
        title: "Aktionen",
        dataIndex: "id",
        render: (id, row) => {
          return (
            React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 227}}
              , React.createElement(Icon, {
                icon: "fa:file-upload",
                style: { cursor: "pointer" },
                title: "Nachweis hochladen" ,
                onClick: () => {
                  this.createMeasureProve(row.year);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}
              )
              , React.createElement(Icon, {
                icon: "fa:pen",
                style: { cursor: "pointer" },
                title: "Maßnahme bearbeiten" ,
                onClick: () => {
                  this.updateMeasureYear(id);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 236}}
              )
              , React.createElement(Icon, {
                icon: "fa:trash",
                style: { cursor: "pointer" },
                title: "Maßnahme löschen" ,
                onClick: () => {
                  this.deleteMeasureYear(id);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 244}}
              )
            )
          );
        },
      },
    ];
  }

  updateMeasure() {
    if (this.massnahme) {
      $parse.ui
        .updateObject(this.massnahme.className, this.massnahme.id)
        .then((ok) => {
          if (ok && this.massnahme) this.init(this.massnahme.id);
        });
    }
  }

  deleteMeasure() {
    if (this.massnahme) {
      $parse.ui
        .deleteObject(this.massnahme.className, this.massnahme.id)
        .then((ok) => {
          $framework.router.navigate("/asew/klimazaehler/massnahmen");
        });
    }
  }

  async createMissingYears() {
    if (this.massnahme) {
      for (const jahr of this.missingYears) {
        const obj = new MassnahmeBerechnung({
          massnahme: this.massnahme,
          jahr,
        });

        await obj.save();
      }

      this.init(this.massnahme.id);
    }
  }

  createMeasureYear() {
    if (this.massnahme) {
      const fields = [
        "jahr",
        "faktor",
        ...this.massnahme.kategorie.felder,
        "kommentar",
      ];

      const my = new MassnahmeBerechnung({
        massnahme: this.massnahme,
        jahr: this.missingYears[0],
      });

      $parse.ui
        .createObject(MassnahmeBerechnung.className, my, fields)
        .then((ok) => {
          if (ok && this.massnahme) this.init(this.massnahme.id);
        });
    }
  }

  updateMeasureYear(id) {
    if (this.massnahme) {
      const fields = [
        ...this.massnahme.kategorie.felder,
        "faktor",
        "kommentar",
      ];

      const my = new MassnahmeBerechnung({
        massnahme: this.massnahme,
        jahr: $state.currentYear,
      });

      $parse.ui
        .updateObject(MassnahmeBerechnung.className, id, fields)
        .then((ok) => {
          if (ok && this.massnahme) this.init(this.massnahme.id);
        });
    }
  }

  deleteMeasureYear(id) {
    $parse.ui.deleteObject(MassnahmeBerechnung.className, id).then(() => {
      if (this.massnahme) this.init(this.massnahme.id);
    });
  }

  createMeasureProve(year) {
    if (this.massnahme) {
      const my = new MassnahmeNachweis({
        massnahme: this.massnahme,
        jahr: year || $state.currentYear,
      });

      $parse.ui.createObject(MassnahmeNachweis.className, my).then((ok) => {
        if (ok && this.massnahme) this.init(this.massnahme.id);
      });
    }
  }

  updateMeasureProve(id) {
    if (this.massnahme) {
      $parse.ui.updateObject(MassnahmeNachweis.className, id).then((ok) => {
        if (ok && this.massnahme) this.init(this.massnahme.id);
      });
    }
  }

  deleteMeasureProve(id) {
    $parse.ui.deleteObject(MassnahmeNachweis.className, id).then((ok) => {
      if (ok && this.massnahme) this.init(this.massnahme.id);
    });
  }

  __init4() {this.onChange = async (e) => {
    if (this.massnahme) {
      try {
        this.massnahme.considerForCalc = e.target.checked;
        await this.massnahme.save();
        this.init(this.massnahme.id);
      } catch (error) {
        console.error("lol", error);
        message.error("Keine Berechtigung zur Änderung!");
      }
    }
  }}
}

export const MeasureUpdate = createComponent(function MeasureUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const state = React.useMemo(() => new MeasureUpdateState(id), [id]);

  if (!state.massnahme) {
    return null;
  }

  const subTitle =
    state.massnahme.startDatum.getFullYear() +
    " bis " +
    (state.massnahme.endDatum ? state.massnahme.endDatum.getFullYear() : "∞");

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 399}}
      , React.createElement(AdminToolbar, {
        title: state.massnahme.bezeichnung + " (" + subTitle + ")",
        actions: [
          React.createElement(Button, {
            key: "delete",
            children: "Zurück",
            onClick: () => {
              navigate("/asew/klimazaehler/massnahmen");
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 403}}
          ),
          React.createElement(Button, {
            key: "delete",
            danger: true,
            children: "Löschen",
            onClick: () => {
              state.deleteMeasure();
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 410}}
          ),
          React.createElement(Button, {
            key: "edit",
            children: "Bearbeiten",
            onClick: () => {
              state.updateMeasure();
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 418}}
          ),

          //hier würde ich den Button fürs neue Jahr löschen -CM
          // <Button
          //   key="new"
          //   type="primary"
          //   children="Neues Jahr"
          //   disabled={state.missingYears.length === 0}
          //   onClick={() => {
          //     state.createMeasureYear();
          //   }}
          // />,
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 400}}

        , React.createElement(Table, {
          size: "small",
          rowKey: (row) => row.id,
          columns: state.columns,
          dataSource: state.rows,
          pagination: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 438}}
        )

        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 446}} )

        , React.createElement(Statistic, {
          title: "Einsparungen inkl. Hochrechnung"  ,
          value: $state.getMeasureTotalSaving(state.massnahme),
          suffix: "t CO₂" ,
          precision: 2,
          groupSeparator: ".",
          decimalSeparator: ",", __self: this, __source: {fileName: _jsxFileName, lineNumber: 448}}
        )

        , React.createElement(Checkbox, {
          onChange: state.onChange,
          checked: state.massnahme.considerForCalc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 457}}
, "In KLIMAZähler berücksichtigen"

        )

        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 464}} )

        , React.createElement(Typography.Title, { level: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 466}}, "Nachweise")

        , React.createElement(List, {
          rowKey: (row) => row.id,
          dataSource: state.massnahmenNachweise,
          locale: { emptyText: "Keine Nachweise vorhanden" },
          footer: 
            React.createElement(Button, {
              type: "primary",
              children: "Neuen Nachweis hochladen"  ,
              onClick: () => state.createMeasureProve(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 473}}
            )
          ,
          renderItem: (nachweis) => (
            React.createElement(List.Item, {
              children: 
                React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 482}}
                  , nachweis.jahr, ", " , _optionalChain([nachweis, 'access', _10 => _10.datei, 'access', _11 => _11.name, 'call', _12 => _12(), 'optionalAccess', _13 => _13.slice, 'call', _14 => _14(33)])
                )
              ,
              actions: [
                React.createElement(Button, {
                  key: "update",
                  size: "small",
                  children: "Bearbeiten",
                  onClick: () => state.updateMeasureProve(nachweis.id), __self: this, __source: {fileName: _jsxFileName, lineNumber: 487}}
                ),
                React.createElement(Button, {
                  danger: true,
                  key: "delete",
                  size: "small",
                  children: "Löschen",
                  onClick: () => state.deleteMeasureProve(nachweis.id), __self: this, __source: {fileName: _jsxFileName, lineNumber: 493}}
                ),
                React.createElement(Button, {
                  key: "download",
                  size: "small",
                  children: "Herunterladen",
                  href: _optionalChain([nachweis, 'access', _15 => _15.datei, 'optionalAccess', _16 => _16.url, 'call', _17 => _17()]),
                  target: "_blank",
                  rel: "noopener noreferrer" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 500}}
                ),
              ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 480}}
            )
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 468}}
        )
      )
    )
  );
});
