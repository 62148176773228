import Parse from "parse";














export class KlimaKachel extends Parse.Object {
  static __initStatic() {this.className = "KlimaZaehler_KlimaKachel"}

  constructor(data) {
    super("KlimaZaehler_KlimaKachel", data );
  }

  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get iconPath() {
    return super.get("iconPath");
  }
  set iconPath(value) {
    super.set("iconPath", value);
  }
  get kategorie() {
    return super.get("kategorie");
  }
  set kategorie(value) {
    super.set("kategorie", value);
  }
} KlimaKachel.__initStatic();

Parse.Object.registerSubclass("KlimaZaehler_KlimaKachel", KlimaKachel);
