const _jsxFileName = "/home/runner/work/asew-klimazaehler-admin/asew-klimazaehler-admin/src/js/klimazaehler/components/Footprint.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { AdminLayout, } from "@opendash/core";
import { $parse, Parse } from "@opendash/plugin-parse";
import { createComponent, makeAutoObservable } from "@opendash/state";
import { Button, InputNumber, message, Table } from "antd";

import { runInAction } from "mobx";
import React from "react";
import { $state } from "../state";
import { UnterKategorie } from "../types-generated";
import { AdminToolbar } from "@opendash/ui";

const currentYear = new Date().getFullYear().toString();
const lastYear = "" + (new Date().getFullYear() - 1);







class FootprintState {
   __init() {this.kategorien = []}
   __init2() {this.changes = {}}

  constructor() {;FootprintState.prototype.__init.call(this);FootprintState.prototype.__init2.call(this);
    makeAutoObservable(this);

    this.init();
  }

   async init() {
    const kategorien = await new Parse.Query(UnterKategorie).find();

    runInAction(() => {
      this.kategorien = kategorien;
    });
  }
  get rows() {
    return this.kategorien.map((kategorie) => {
      return {
        kategorie,
        label: kategorie.bezeichnung,
        unit: kategorie.einheit,
      };
    });
  }

  get columns() {
    return [
      {
        title: "Kategorie",
        dataIndex: "label",
        key: "label",
        fixed: "left",
        width: 200,
      },
      {
        title: "Einheit",
        dataIndex: "unit",
        key: "unit",
        fixed: "left",
        width: 100,
      },
      ...$state.yearStrings.map((year) => {
        return {
          title: year,
          dataIndex: "kategorie",
          key: "year-" + year,
          render: (kategorie) => (
            React.createElement(FootprintInputCell, {
              state: this,
              kategorie: kategorie,
              year: year, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
            )
          ),
        };
      }),
    ];
  }

  updateFactor(kategorie, year, value) {
    this.changes[kategorie.id] = this.changes[kategorie.id] || {};
    this.changes[kategorie.id][year] = parseFloat(
      value.toString().replace(",", ".")
    );
  }

  save() {
    for (const kategorie of this.kategorien) {
      kategorie.faktoren = Object.assign(
        {},
        kategorie.faktoren,
        this.changes[kategorie.id]
      );
    }

    Parse.Object.saveAll(this.kategorien)
      .then(() => {
        message.success("Vermeidungsfaktoren gespeichert");

        this.discard();
      })
      .catch((error) => {
        console.error(error);

        message.error("Vermeidungsfaktoren konnten nicht gespeichert werden");
      });
  }

  discard() {
    this.changes = {};
  }

  takeOverLastYear() {
    for (const kategorie of this.kategorien) {
      this.updateFactor(kategorie, currentYear, kategorie.faktoren[lastYear]);
    }
  }

  canEdit() {
    return $parse.ui.getClassPermission(UnterKategorie.className, "create");
  }
}

export const Footprint = createComponent(function Footprint() {
  const state = React.useMemo(() => new FootprintState(), []);

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
      , React.createElement(AdminToolbar, {
        title: "Vermeidungsfaktoren",
        actions: 
          state.canEdit() && [
            React.createElement(Button, {
              key: "transform",
              children: "Aktuelles Jahr aus Vorjahr übernehmen"    ,
              onClick: () => {
                state.takeOverLastYear();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
            ),
            React.createElement(Button, {
              key: "discard",
              children: "Verwerfen",
              disabled: Object.keys(state.changes).length === 0,
              onClick: () => {
                state.discard();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}
            ),
            React.createElement(Button, {
              key: "save",
              children: "Speichern",
              disabled: Object.keys(state.changes).length === 0,
              onClick: () => {
                state.save();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
            ),
          ]
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
)

      , React.createElement('div', { style: { width: "100%", backgroundColor: "white", padding: "24px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}
        , React.createElement(Table, {
          rowKey: (row) => row.kategorie.id,
          columns: state.columns,
          dataSource: state.rows,
          pagination: false,
          scroll: { x: "max-content", y: "calc(100vh - 230px)" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
        )
      )
    )
  );
});

const FootprintInputCell = createComponent



(function Footprint({ state, kategorie, year }) {
  const wert = _nullishCoalesce(kategorie.faktoren[year], () => ( 0));
  const initialValue = new Intl.NumberFormat("de-DE", {}).format(wert) || 0;

  if (!state.canEdit()) {
    return initialValue;
  }

  // if (year !== currentYear) {
  //   return initialValue;
  // }

  const value = _optionalChain([state, 'access', _ => _.changes, 'access', _2 => _2[kategorie.id], 'optionalAccess', _3 => _3[year]]) || initialValue;

  return (
    React.createElement(InputNumber, {
      value: value,
      parser: (v) => parseFloat(v.toString().replaceAll(",", ".")) || 0,
      decimalSeparator: ",",
      onChange: (newValue) => {
        state.updateFactor(kategorie, year, newValue);
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 193}}
    )
  );
});
