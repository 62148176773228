const _jsxFileName = "/home/runner/work/asew-klimazaehler-admin/asew-klimazaehler-admin/src/js/klimazaehler/components/StartPage.tsx";import { AdminLayout, useTranslation } from "@opendash/core";
import { createComponent } from "@opendash/state";
import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { AdminToolbar } from "@opendash/ui";

const links = [
  {
    description: "klimazaehler:start.faq_desc",
    linkLabel: "klimazaehler:start.faq_link",
    linkUrl:
      "https://www.asew.de/asewGips/ASEW/asew.de/produkte/Beratung-und-Vertrieb/Klimazaehler/Klimazaehler/ASEW-KLIMAZaehler-FAQ.pdf",
    download: true,
  },
  {
    description: "klimazaehler:start.calc_desc",
    linkLabel: "klimazaehler:start.calc_link",
    linkUrl:
      "https://www.asew.de/asewGips/ASEW/asew.de/produkte/Beratung-und-Vertrieb/Klimazaehler/Klimazaehler/ASEW-KLIMAZaehler-Berechnungsgrundlagen.pdf",
    download: true,
  },
  {
    description: "klimazaehler:start.guide_desc",
    linkLabel: "klimazaehler:start.guide_link",
    linkUrl:
      "https://www.asew.de/asewGips/ASEW/asew.de/produkte/Beratung-und-Vertrieb/Klimazaehler/Klimazaehler/ASEW-KLIMAZaehler-Berechnung-Vermeidungsfaktoren-Fernwaerme-BHKW.xlsx",
    download: true,
  },
  {
    description: "klimazaehler:start.handbook_desc",
    linkLabel: "klimazaehler:start.handbook_link",
    linkUrl:
      "https://www.asew.de/asewGips/ASEW/asew.de/produkte/Beratung-und-Vertrieb/Klimazaehler/KLIMAZaehler-Handbuch.pdf",
    download: true,
  },
];

export const StartPage = createComponent(function StartPage() {
  const t = useTranslation();

  // return <AdminLayout contentPadding />;

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      , React.createElement(AdminToolbar, { title: t("klimazaehler:start.hl"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
        , React.createElement(Typography.Paragraph, { children: t("klimazaehler:start.intro"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}} )
        , React.createElement(Typography.Paragraph, { children: t("klimazaehler:start.intro2"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}} )

        , React.createElement('ul', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
          , links.map((link, i) => (
            React.createElement('li', { key: i, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
              , React.createElement(Link, { children: t(link.linkLabel), to: link.linkUrl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
            )
          ))
        )

        , React.createElement('div', {
          dangerouslySetInnerHTML: {
            __html: t("klimazaehler:start.contact"),
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
        )

        , React.createElement('img', { src: "/startseite.svg", width: "400px", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} )
      )
    )
  );
});
