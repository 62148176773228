const _jsxFileName = "/home/runner/work/asew-klimazaehler-admin/asew-klimazaehler-admin/src/js/klimazaehler/components/TenantConfigRoute.tsx";
import { AdminLayout } from "@opendash/core";
import { ParseObject } from "@opendash/plugin-parse";
import { createComponent, makeAutoObservable } from "@opendash/state";
import { runInAction } from "mobx";
import React from "react";
import { $state } from "../state";
import { AdminToolbar } from "@opendash/ui";

class TenantConfigRouteState {
  constructor() {
    makeAutoObservable(this);

    this.init();
  }

   async init() {
    runInAction(() => {});
  }
}

export const TenantConfigRoute = createComponent(function TenantConfigRoute() {
  if (!$state.tenantConfig) {
    return (
      React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
        , React.createElement('div', {
          style: {
            margin: "-24px",
            height: `calc(100% + 48px)`,
            backgroundColor: "#fff",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}

          , React.createElement(AdminToolbar, { title: "Einstellungen: Allgemein" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}, "Ladefehler: Ihr Nutzer ist keinem Mandant zugeordnet oder der Mandant hat keine Konfiguration hinterlegt."


          )
        )
      )
    );
  }

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
      , React.createElement(ParseObject, {
        title: "Einstellungen: Allgemein" ,
        subTitle: "Die hierin vorgenommenen Einstellungen und Dateneingaben beziehen sich\r vorrangig auf den Gesamtzähler - die Farben hingegen auch auf den\r Maßnahmenzähler sowie die Klimakurven."

             ,
        disableDelete: true,
        object: $state.tenantConfig, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      )
    )
  );
});
