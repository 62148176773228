 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { $framework } from "@opendash/core";
import { Tenant } from "@opendash/plugin-parse/dist/types-generated";
import { makeAutoObservable } from "@opendash/state";
import Parse from "parse";
import {


  TenantConfig,
} from "../types-generated";
import { ImportExportService } from "./ImportExportService";






































































































































export class GlobalState {
   __init() {this.currentYear = new Date().getFullYear()}
   __init2() {this.lastYear = new Date().getFullYear() - 1}
   __init3() {this.firstYear = 2010}

   __init4() {this.tenant = null}
   __init5() {this.tenantConfig = null}

   __init6() {this._tenantFilter = null}

   __init7() {this.outputs = null}

   __init8() {this.importExportService = new ImportExportService(this)}

  constructor() {;GlobalState.prototype.__init.call(this);GlobalState.prototype.__init2.call(this);GlobalState.prototype.__init3.call(this);GlobalState.prototype.__init4.call(this);GlobalState.prototype.__init5.call(this);GlobalState.prototype.__init6.call(this);GlobalState.prototype.__init7.call(this);GlobalState.prototype.__init8.call(this);
    makeAutoObservable(this);

    const cancel = $framework.services.UserService.subscribe(() => {
      if ($framework.services.UserService.isLoggedIn()) {
        cancel();
        this.init();
      }
    });
  }

  get tenantFilter() {
    return this._tenantFilter || _optionalChain([this, 'access', _ => _.tenant, 'optionalAccess', _2 => _2.id]) || null;
  }

   async init() {
    const user = Parse.User.current();

    this.tenant = (_optionalChain([user, 'optionalAccess', _3 => _3.get, 'call', _4 => _4("tenant")]) || null) ;

    if (this.tenant) {
      await this.tenant.fetch();

      const config = await new Parse.Query(TenantConfig)
        .equalTo("tenant", this.tenant)
        .first();

      this.tenantConfig = config || null;
    }

    await this.fetchOutputs();
  }

   async fetchOutputs() {
    if (this.tenantFilter) {
      const outputs = await Parse.Cloud.run("asew-klimazaehler-output", {
        tenant: this.tenantFilter,
      });

      this.setOutputs(outputs || null);
    }
  }

   setOutputs(outputs) {
    this.outputs = outputs;
  }

  get years() {
    const years = [];

    for (let year = this.firstYear; year <= this.currentYear; year++) {
      years.push(year);
    }

    return years.reverse();
  }

  get yearStrings() {
    return this.years.map((year) => year.toString());
  }

  getTotalSaving() {
    return _optionalChain([this, 'access', _5 => _5.outputs, 'optionalAccess', _6 => _6.currentValue]) || 0;
  }

  getYearSaving(year) {
    return _optionalChain([this, 'access', _7 => _7.outputs, 'optionalAccess', _8 => _8.fullHistory, 'access', _9 => _9.find, 'call', _10 => _10((h) => h.year === year), 'optionalAccess', _11 => _11.value]) || 0;
  }

  getMeasureTotalSaving(m) {
    if (!m) {
      return 0;
    }

    return _optionalChain([this, 'access', _12 => _12.outputs, 'optionalAccess', _13 => _13.measureValues, 'access', _14 => _14[m.id], 'optionalAccess', _15 => _15.value]) || 0;
  }

  getMeasureYearSaving(mj) {
    if (!mj) {
      return 0;
    }

    return _optionalChain([this, 'access', _16 => _16.outputs, 'optionalAccess', _17 => _17.measureYearValues, 'access', _18 => _18[mj.id], 'optionalAccess', _19 => _19.value]) || 0;
  }

  setTenantFilter(tenantId) {
    this._tenantFilter = tenantId;

    this.fetchOutputs();
  }

  async getTenant() {
    if (!this.tenantFilter) {
      return undefined;
    }

    return await new Parse.Query(Tenant).get(this.tenantFilter);
  }
}
