import Parse from "parse";





















export class KlimaKurveJahr extends Parse.Object {
  static __initStatic() {this.className = "KlimaZaehler_KlimaKurveJahr"}

  constructor(data) {
    super("KlimaZaehler_KlimaKurveJahr", data );
  }

  get footprintActual() {
    return super.get("footprintActual");
  }
  set footprintActual(value) {
    super.set("footprintActual", value);
  }
  get footprintActualText() {
    return super.get("footprintActualText");
  }
  set footprintActualText(value) {
    super.set("footprintActualText", value);
  }
  get footprintTarget() {
    return super.get("footprintTarget");
  }
  set footprintTarget(value) {
    super.set("footprintTarget", value);
  }
  get footprintTargetText() {
    return super.get("footprintTargetText");
  }
  set footprintTargetText(value) {
    super.set("footprintTargetText", value);
  }
  get handprintText() {
    return super.get("handprintText");
  }
  set handprintText(value) {
    super.set("handprintText", value);
  }
  get scope1() {
    return super.get("scope1");
  }
  set scope1(value) {
    super.set("scope1", value);
  }
  get scope2() {
    return super.get("scope2");
  }
  set scope2(value) {
    super.set("scope2", value);
  }
  get scope3() {
    return super.get("scope3");
  }
  set scope3(value) {
    super.set("scope3", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get year() {
    return super.get("year");
  }
  set year(value) {
    super.set("year", value);
  }
} KlimaKurveJahr.__initStatic();

Parse.Object.registerSubclass("KlimaZaehler_KlimaKurveJahr", KlimaKurveJahr);
