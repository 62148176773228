import Parse from "parse";

















export class KlimaKachelEinstellungen extends Parse.Object {
  static __initStatic() {this.className = "KlimaZaehler_KlimaKachelEinstellungen"}

  constructor(data) {
    super("KlimaZaehler_KlimaKachelEinstellungen", data );
  }

  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get kachel() {
    return super.get("kachel");
  }
  set kachel(value) {
    super.set("kachel", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get url() {
    return super.get("url");
  }
  set url(value) {
    super.set("url", value);
  }
} KlimaKachelEinstellungen.__initStatic();

Parse.Object.registerSubclass("KlimaZaehler_KlimaKachelEinstellungen", KlimaKachelEinstellungen);
