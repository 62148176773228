import Parse from "parse";













export class Kategorie extends Parse.Object {
  static __initStatic() {this.className = "KlimaZaehler_Kategorie"}

  constructor(data) {
    super("KlimaZaehler_Kategorie", data );
  }

  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get farbe() {
    return super.get("farbe");
  }
  set farbe(value) {
    super.set("farbe", value);
  }
  get felder() {
    return super.get("felder");
  }
  set felder(value) {
    super.set("felder", value);
  }
  get order() {
    return super.get("order");
  }
  set order(value) {
    super.set("order", value);
  }
} Kategorie.__initStatic();

Parse.Object.registerSubclass("KlimaZaehler_Kategorie", Kategorie);
