import Parse from "parse";



























export class TenantConfig extends Parse.Object {
  static __initStatic() {this.className = "KlimaZaehler_TenantConfig"}

  constructor(data) {
    super("KlimaZaehler_TenantConfig", data );
  }

  get backgroundColor() {
    return super.get("backgroundColor");
  }
  set backgroundColor(value) {
    super.set("backgroundColor", value);
  }
  get backgroundDimmColor() {
    return super.get("backgroundDimmColor");
  }
  set backgroundDimmColor(value) {
    super.set("backgroundDimmColor", value);
  }
  get backgroundImage() {
    return super.get("backgroundImage");
  }
  set backgroundImage(value) {
    super.set("backgroundImage", value);
  }
  get comparativeFigures() {
    return super.get("comparativeFigures");
  }
  set comparativeFigures(value) {
    super.set("comparativeFigures", value);
  }
  get coverageAreaName() {
    return super.get("coverageAreaName");
  }
  set coverageAreaName(value) {
    super.set("coverageAreaName", value);
  }
  get coverageAreaPopulation() {
    return super.get("coverageAreaPopulation");
  }
  set coverageAreaPopulation(value) {
    super.set("coverageAreaPopulation", value);
  }
  get coverageAreaSize() {
    return super.get("coverageAreaSize");
  }
  set coverageAreaSize(value) {
    super.set("coverageAreaSize", value);
  }
  get footprintActualColor() {
    return super.get("footprintActualColor");
  }
  set footprintActualColor(value) {
    super.set("footprintActualColor", value);
  }
  get footprintTargetColor() {
    return super.get("footprintTargetColor");
  }
  set footprintTargetColor(value) {
    super.set("footprintTargetColor", value);
  }
  get handprintColor() {
    return super.get("handprintColor");
  }
  set handprintColor(value) {
    super.set("handprintColor", value);
  }
  get memberOfKlimaschutzInitiative() {
    return super.get("memberOfKlimaschutzInitiative");
  }
  set memberOfKlimaschutzInitiative(value) {
    super.set("memberOfKlimaschutzInitiative", value);
  }
  get numberOfCustomers() {
    return super.get("numberOfCustomers");
  }
  set numberOfCustomers(value) {
    super.set("numberOfCustomers", value);
  }
  get primaryColor() {
    return super.get("primaryColor");
  }
  set primaryColor(value) {
    super.set("primaryColor", value);
  }
  get secondaryColor() {
    return super.get("secondaryColor");
  }
  set secondaryColor(value) {
    super.set("secondaryColor", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get startOfConsider() {
    return super.get("startOfConsider");
  }
  set startOfConsider(value) {
      super.set("startOfConsider", value);
  }
} TenantConfig.__initStatic();

Parse.Object.registerSubclass("KlimaZaehler_TenantConfig", TenantConfig);
