const _jsxFileName = "/home/runner/work/asew-klimazaehler-admin/asew-klimazaehler-admin/src/js/klimazaehler/components/FactorTable.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
import { AdminLayout } from "@opendash/core";
import { $parse, Parse } from "@opendash/plugin-parse";
import { createComponent, makeAutoObservable } from "@opendash/state";
import { Button, InputNumber, Table, message } from "antd";

import { runInAction } from "mobx";
import React from "react";
import { $state } from "../state";
import { UnterKategorie } from "../types-generated";
import { AdminToolbar } from "@opendash/ui";

//- Erstellt eine Konstante currentYear, die das aktuelle Jahr als String speichert.
const currentYear = new Date().getFullYear().toString();

// Erstellt eine Konstante lastYear, die das letzte Jahr als String speichert.
const lastYear = "" + (new Date().getFullYear() - 1);

//Definiert einen neuen Typ RecordType, der für die Datensätze in der Tabelle verwendet wird.





//Definiert eine neue Klasse FactorTableState, die den Zustand der Tabelle verwaltet.
class FactorTableState {
  //Definiert eine private Variable kategorien, die eine Liste von UnterKategorie Objekten speichert.
   __init() {this.kategorien = []}

  //Definiert eine öffentliche Variable changes, die ein Objekt speichert, das die Änderungen an den Faktoren speichert.
   __init2() {this.changes = {}}

  //Definiert den Konstruktor der Klasse, der aufgerufen wird, wenn eine neue Instanz der Klasse erstellt wird. Der Konstruktor ruft die init() Methode auf.
  constructor() {;FactorTableState.prototype.__init.call(this);FactorTableState.prototype.__init2.call(this);
    makeAutoObservable(this);

    this.init();
  }
  //Definiert eine private asynchrone Methode init(), die Daten von einer Datenbank abruft und sie in der kategorien Variable speichert.
   async init() {
    const kategorien = await new Parse.Query(UnterKategorie)
      .ascending("bezeichnung")
      .find();

    runInAction(() => {
      this.kategorien = kategorien.sort((a, b) => {
        if (a.bezeichnung.startsWith("_")) return 1;
        if (b.bezeichnung.startsWith("_")) return -1;
        return a.bezeichnung.localeCompare(b.bezeichnung);
      });
    });
  }
  //Definiert einen Getter rows(), der die Daten für die Zeilen der Tabelle zurückgibt.
  get rows() {
    return this.kategorien.map((kategorie) => {
      return {
        kategorie,
        label: kategorie.bezeichnung,
        unit: kategorie.einheit,
      };
    });
  }
  // Definiert einen Getter columns(), der die Spalten der Tabelle zurückgibt.
  get columns() {
    return [
      {
        title: "Kategorie",
        dataIndex: "label",
        key: "label",
        fixed: "left",
        width: 200,
      },
      {
        title: "Einheit",
        dataIndex: "unit",
        key: "unit",
        fixed: "left",
        width: 100,
        render: (unit) => {
          return React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}, "g/", unit);
        },
      },
      ...$state.yearStrings.map((year) => {
        return {
          title: year,
          dataIndex: "kategorie",
          key: "year-" + year,
          render: (kategorie) => (
            React.createElement(FactorTableInputCell, {
              state: this,
              kategorie: kategorie,
              year: year, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
            )
          ),
        };
      }),
    ];
  }
  //Definiert eine Methode updateFactor(), die einen Faktor in der changes Variable aktualisiert.
  updateFactor(kategorie, year, value) {
    this.changes[kategorie.id] = this.changes[kategorie.id] || {};
    this.changes[kategorie.id][year] = parseFloat(
      value.toString().replace(",", ".")
    );
  }
  //Definiert eine Methode save(), die alle Änderungen in der Datenbank speichert und die changes Variable zurücksetzt.
  save() {
    for (const kategorie of this.kategorien) {
      kategorie.faktoren = Object.assign(
        {},
        kategorie.faktoren,
        this.changes[kategorie.id]
      );
    }

    Parse.Object.saveAll(this.kategorien)
      .then(() => {
        message.success("Vermeidungsfaktoren gespeichert");

        this.discard();
      })
      .catch((error) => {
        console.error(error);

        message.error("Vermeidungsfaktoren konnten nicht gespeichert werden");
      });
  }
  //Definiert eine Methode discard(), die alle Änderungen verwirft, indem sie die changes Variable zurücksetzt.
  discard() {
    this.changes = {};
  }
  //Definiert eine Methode takeOverLastYear(), die die Faktoren des letzten Jahres in das aktuelle Jahr kopiert.
  takeOverLastYear() {
    for (const kategorie of this.kategorien) {
      this.updateFactor(kategorie, currentYear, kategorie.faktoren[lastYear]);
    }
  }
  //Definiert eine Methode canEdit(), die überprüft, ob der Benutzer die Berechtigung hat, Daten zu erstellen.
  canEdit() {
    return $parse.ui.getClassPermission(UnterKategorie.className, "create");
  }
}
// Definiert eine neue Komponente FactorTable, die die Tabelle darstellt.
export const FactorTable = createComponent(function FactorTable() {
  const state = React.useMemo(() => new FactorTableState(), []);

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
      /* <div
        style={{
          margin: "-24px",
          height: `calc(100% + 48px)`,
          backgroundColor: "#fff",
        }}
      >*/
      , React.createElement(AdminToolbar, {
        title: "Vermeidungsfaktoren",
        actions: 
          state.canEdit() && [
            React.createElement(Button, {
              key: "transform",
              children: "Aktuelles Jahr aus Vorjahr übernehmen"    ,
              onClick: () => {
                state.takeOverLastYear();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
            ),
            React.createElement(Button, {
              key: "discard",
              children: "Verwerfen",
              disabled: Object.keys(state.changes).length === 0,
              onClick: () => {
                state.discard();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
            ),
            React.createElement(Button, {
              key: "save",
              type: "primary",
              children: "Speichern",
              disabled: Object.keys(state.changes).length === 0,
              onClick: () => {
                state.save();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}}
            ),
          ]
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}

        , !state.canEdit() && (
          React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 188}}, "Diese Tabelle dient nur zur Information und kann nicht bearbeitet werden. Die Quellenangaben und Berechnungen dieser Werte finden sich in der Datei „Berechnungsgrundlagen“ (siehe Startseite)."



          )
        )
      )

      , React.createElement('div', { style: { width: "100%", backgroundColor: "white", padding: "24px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 196}}
        , React.createElement(Table, {
          rowKey: (row) => row.kategorie.id,
          columns: state.columns,
          dataSource: state.rows,
          pagination: false,
          scroll: { x: "max-content", y: "calc(100vh - 290px)" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 197}}
        )
      )
    )
  );
});
//- Definiert eine neue Komponente FactorTableInputCell, die eine Zelle in der Tabelle darstellt, in der der Benutzer einen Wert eingeben kann.
const FactorTableInputCell = createComponent



(function FactorTable({ state, kategorie, year }) {
  const wert = _nullishCoalesce(kategorie.faktoren[year], () => ( 0));
  const initialValue = new Intl.NumberFormat("de-DE", {}).format(wert) || 0;

  if (!state.canEdit()) {
    return initialValue;
  }

  // if (year !== currentYear) {
  //   return initialValue;
  // }

  const value = _optionalChain([state, 'access', _ => _.changes, 'access', _2 => _2[kategorie.id], 'optionalAccess', _3 => _3[year]]) || initialValue;

  return (
    React.createElement(InputNumber, {
      value: value,
      decimalSeparator: ",",
      parser: (v) => parseFloat(v.toString().replaceAll(",", ".")) || 0,
      onChange: (newValue) => {
        state.updateFactor(kategorie, year, newValue);
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}
    )
  );
});
