const _jsxFileName = "/home/runner/work/asew-klimazaehler-admin/asew-klimazaehler-admin/src/js/klimazaehler/custom/EditStartYear.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useState } from "react";

import { useTranslation, } from "@opendash/core";

import { Button, DatePicker, Modal } from "antd";




export const EditStartYear = (props) => {
  const t = useTranslation();
  const { value, setValue, fieldname, parseObject } = props;
  const [year, setYear] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  React.useEffect(() => {}, []);

  const handleOk = async () => {
    if (year !== undefined) {
      try {
        const yearClean = new Date(
          new Date(year).getFullYear(),
          0,
          1,
          12,
          0,
          0
        );
        _optionalChain([props, 'access', _ => _.parseObject, 'optionalAccess', _2 => _2.set, 'call', _3 => _3("startOfConsider", yearClean)]);
        props.setValue(yearClean);
      } catch (e) {
        console.error(e);
      }
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setYears = async (years) => {
    setYear(years);
  };

  return props.setValue ? (
    React.createElement(React.Fragment, null
      , React.createElement(Button, {
        style: { width: "100%", textAlign: "left" },
        type: "default",
        onClick: () => {
          setIsModalOpen(!isModalOpen);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}

        , React.createElement('div', { style: { width: "100%", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
          , _optionalChain([props, 'access', _4 => _4.parseObject, 'access', _5 => _5.get, 'call', _6 => _6("startOfConsider"), 'optionalAccess', _7 => _7.getFullYear, 'call', _8 => _8(), 'access', _9 => _9.toString, 'call', _10 => _10()])
        )
      )
      , React.createElement(Modal, {
        open: isModalOpen,
        onOk: handleOk,
        onCancel: handleCancel,
        title: "Ab wann sollen Maßnahmen für die Berechnung berücksichtigt werden"        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}

        , React.createElement(DatePicker, {
          style: { width: "100%", textAlign: "left" },
          picker: "year",
          onChange: setYears, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        )
      )
    )
  ) : (
    React.createElement('div', { style: { textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
      , _optionalChain([props, 'access', _11 => _11.parseObject, 'access', _12 => _12.get, 'call', _13 => _13("startOfConsider"), 'optionalAccess', _14 => _14.getFullYear, 'call', _15 => _15(), 'access', _16 => _16.toString, 'call', _17 => _17()])
    )
  );
};
export default EditStartYear;
