import "antd/dist/reset.css";

import "./parse.config";

import { init, StorageAdapterLS } from "@opendash/core";
import { translate } from "@opendash/i18n";
import { registerIconPack } from "@opendash/icons";
import { $parse, ParsePlugin } from "@opendash/plugin-parse/";
import { ASEWKlimazaehlerPlugin } from "./klimazaehler/Plugin";

// import "antd/dist/antd.variable.min.css";

init("opendash", async (factory) => {
  // Logo:
  factory.ui.setLogoImage(require("../assets/logo.png"));

  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Font:
  const fontCss = document.createElement("link");
  fontCss.setAttribute("rel", "stylesheet");
  fontCss.setAttribute("type", "text/css");
  fontCss.setAttribute(
    "href",
    "https://asew.klimazaehler.de/fonts/roboto/style.css"
  );
  document.head.appendChild(fontCss);

  // Translations:
  factory.registerLanguage("de", "Deutsch", undefined, true);

  // ant design translations

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    async () => await import("./translations/de.json")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(
    new ParsePlugin({
      useLiveQueries: false,
      lostPassword: false,
      createAccount: false,
      usePagination: 1000,
      disableNavigationItems: true,
    })
  );

  await factory.use(new ASEWKlimazaehlerPlugin());

  $parse.ui.setClassConfig({
    className: "_User",
    translationPrefix: "parse-custom:classes.",
    disableACLEditing: true,
    titleFields: ["username"],
    displayFields: [
      "name",
      "username",
      "email",
      "tenant",
      "tenantAdmin",
      "tenantVerified",
    ],
    createFields: [
      "name",
      "username",
      "email",
      "password",
      "tenant",
      "tenantAdmin",
      "tenantVerified",
    ],
    editFields: [
      "name",
      "username",
      "email",
      "password",
      "tenant",
      "tenantAdmin",
      "tenantVerified",
    ],
  });

  $parse.ui.setDefaultView("_User", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "OD_Tenant",
    translationPrefix: "parse-custom:classes.",
    disableACLEditing: true,
    titleFields: ["label"],
    displayFields: ["label"],
    createFields: ["label"],
    editFields: ["label"],
  });

  $parse.ui.setDefaultView("OD_Tenant", {
    type: "table",
  });
}).then(async () => {
  await translate("parse-custom:test");
});
