const _jsxFileName = "/home/runner/work/asew-klimazaehler-admin/asew-klimazaehler-admin/src/js/klimazaehler/components/OutputPreview.tsx";import { AdminLayout } from "@opendash/core";
import { createComponent, makeAutoObservable } from "@opendash/state";
import { PageHeader } from "@ant-design/pro-layout";
import { runInAction } from "mobx";
import React from "react";
import { $state } from "../state";
import { useParams } from "react-router-dom";
import { AdminToolbar, DangerouslySetHtmlContent } from "@opendash/ui";

class OutputPreviewState {
  constructor() {
    makeAutoObservable(this);

    this.init();
  }

   async init() {
    runInAction(() => {});
  }
}

export const OutputPreview = createComponent(function OutputPreview() {
  const state = React.useMemo(() => new OutputPreviewState(), []);

  const { type } = useParams();

  if (!$state.tenantFilter) {
    return (
      React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        , React.createElement(PageHeader, { title: "Ausgabe Vorschau" , subTitle: null, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}, "Ladefehler: Ihr Nutzer ist keinem Mandant zugeordnet oder der Mandant hat keine Konfiguration hinterlegt."


          )
        )
      )
    );
  }

  const host = "https://iframe.klimazaehler.de";
  const iframeUrl = `${host}/klimazaehler/iframe/${$state.tenantFilter}/${type}`;
  const scriptUrl = `${host}/assets/klimazaehler.js`;
  const id = `asew-klimazaehler-iframe-${type}`;
  const scriptTag = `<script src="${scriptUrl}"></script>`;
  const iframeTag = `<iframe id="${id}" src="${iframeUrl}" style="border: 0; width: 100%; height: 450px;"></iframe>`;

  const code = [iframeTag, scriptTag].join("\n");

  const title =
    type === "gesamtzaehler"
      ? "Ausgabe: Gesamtzähler"
      : type === "massnahmenzaehler"
      ? "Ausgabe: Maßnahmenzähler"
      : type === "klimakurve"
      ? "Ausgabe: Klimakurven"
      : "Ausgabe Vorschau";

  return (
    React.createElement(AdminLayout, { key: type, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
      , React.createElement(AdminToolbar, { title: title, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}, "Das iframe kann mit folgendem Einbettungscode auf Ihrer Webseite angezeigt werden:"


        )

        , React.createElement('pre', { children: code, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}} )
      )

      , React.createElement('div', { style: { width: "100%", backgroundColor: "white", padding: "24px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}, "Hier eine Vorschau:"  )

        , React.createElement(DangerouslySetHtmlContent, {
          html: code,
          style: { width: type === "gesamtzaehler" ? "254px" : undefined }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
        )
      )
    )
  );
});
