 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
import { translate } from "@opendash/i18n";
import { Parse, $parse, } from "@opendash/plugin-parse";
import { FactorTable } from "./components/FactorTable";
import { Footprint } from "./components/Footprint";
import { GlobalComponent } from "./components/GlobalComponent";
import { MeasureOverview } from "./components/MeasureOverview";
import { MeasureUpdate } from "./components/MeasureUpdate";
import { OutputPreview } from "./components/OutputPreview";
import { StartPage } from "./components/StartPage";
import { TenantConfigRoute } from "./components/TenantConfigRoute";
import { $state } from "./state";
import {
  Kategorie,
  KlimaKachel,
  KlimaKachelEinstellungen,
  KlimaKurveJahr,
  Massnahme,
  MassnahmeBerechnung,
  MassnahmeNachweis,
  TenantConfig,
  UnterKategorie,
} from "./types-generated";
import EditStartYear from "./custom/EditStartYear";

export class ASEWKlimazaehlerPlugin  {
  __init() {this.name = "ASEWKlimazaehlerPlugin"}

  constructor() {;ASEWKlimazaehlerPlugin.prototype.__init.call(this);}

  async onFactory(factory) {
    // Globals:
    factory.registerGlobalComponent(GlobalComponent);

    // Routes:

    factory.registerRoute({
      path: "/",
      redirectPath: "/asew/klimazaehler/start",
    });

    factory.registerRoute({
      path: "/asew/klimazaehler",
      redirectPath: "/asew/klimazaehler/start",
    });

    factory.registerRoute({
      path: "/asew/klimazaehler/start",
      component: async () => ({ default: StartPage }),
    });

    factory.registerRoute({
      path: "/asew/klimazaehler/basisdaten",
      component: async () => ({ default: TenantConfigRoute }),
    });

    factory.registerRoute({
      path: "/asew/klimazaehler/faktoren",
      component: async () => ({ default: FactorTable }),
    });

    factory.registerRoute({
      path: "/asew/klimazaehler/footprint",
      component: async () => ({ default: Footprint }),
    });

    factory.registerRoute({
      path: "/asew/klimazaehler/massnahmen",
      component: async () => ({ default: MeasureOverview }),
    });

    factory.registerRoute({
      path: "/asew/klimazaehler/massnahmen/update/:id",
      component: async () => ({ default: MeasureUpdate }),
    });

    factory.registerRoute({
      path: "/asew/klimazaehler/ausgabe/:type",
      component: async () => ({ default: OutputPreview }),
    });

    // Parse Classes

    $parse.ui.setClassConfig({
      className: Kategorie.className,
      translationPrefix: "klimazaehler:classes.",
      disableACLEditing: true,
      titleFields: ["bezeichnung"],
      displayFields: ["bezeichnung", "farbe"],
      createFields: ["bezeichnung", "farbe"],
      editFields: ["bezeichnung", "farbe"],
    });

    $parse.ui.setDefaultView(Kategorie.className, {
      type: "table",
    });

    $parse.ui.setClassConfig({
      className: UnterKategorie.className,
      translationPrefix: "klimazaehler:classes.",
      disableACLEditing: true,
      titleFields: ["bezeichnung"],
      displayFields: [
        "bezeichnung",
        "einheit",
        "felder",
        "felderKurz",
        "kategorie",
        "kachel",
        "formel",
      ],
      createFields: [
        "bezeichnung",
        "einheit",
        "felder",
        "felderKurz",
        "kategorie",
        "kachel",
        "formel",
      ],
      editFields: [
        "bezeichnung",
        "einheit",
        "felder",
        "felderKurz",
        "kategorie",
        "kachel",
        "formel",
      ],
      asSelect: {
        felder: [
          ["absatz", "Absatz (in kWh)"],
          ["absatz_kg", "Absatz (in kg)"],
          ["adresse", "Adresse"],
          ["anteilBioerdgas", "Anteil Bioerdgas"],
          ["anzahl", "Anzahl"],
          ["brennstoffeinsatz", "Brennstoffeinsatz"],
          ["emissionsfaktor", "Emissionsfaktor"],
          ["erzeugteWaerme", "Erzeugte Wärme"],
          ["erzeugterStrom", "Erzeugter Strom"],
          ["fahrleistung", "Fahrleistung"],
          ["foerdervolumen", "Fördervolumen"],
          ["kundenanzahl", "Kundenanzahl"],
          ["kundengruppe", "Kundengruppe"],
          ["menge", "THG-Einsparung"],
          ["nennleistung", "Nennleistung"],
          ["oekofondAufschlag", "Ökofond Aufschlag"],
          ["oekofondEinnahmen", "Ökofond Einnahmen"],
          ["personenkm", "Personenkilometer"],
        ],
        felderKurz: [
          ["absatz", "Absatz (in kWh)"],
          ["absatz_kg", "Absatz (in kg)"],
          ["adresse", "Adresse"],
          ["anteilBioerdgas", "Anteil Bioerdgas"],
          ["anzahl", "Anzahl"],
          ["brennstoffeinsatz", "Brennstoffeinsatz"],
          ["emissionsfaktor", "Emissionsfaktor"],
          ["erzeugteWaerme", "Erzeugte Wärme"],
          ["erzeugterStrom", "Erzeugter Strom"],
          ["fahrleistung", "Fahrleistung"],
          ["foerdervolumen", "Fördervolumen"],
          ["kundenanzahl", "Kundenanzahl"],
          ["kundengruppe", "Kundengruppe"],
          ["menge", "THG-Einsparung"],
          ["nennleistung", "Nennleistung"],
          ["oekofondAufschlag", "Ökofond Aufschlag"],
          ["oekofondEinnahmen", "Ökofond Einnahmen"],
          ["personenkm", "Personenkilometer"],
        ],
        formel: [
          ["absatz", "absatz*faktor"],
          ["absatz_kg", "absatz_kg*faktor"],
          ["anteilBioerdgas", "anteilBioerdgas*faktor"],
          ["anzahl", "anzahl*faktor"],
          ["brennstoffeinsatz", "brennstoffeinsatz*faktor"],
          ["emissionsfaktor", "emissionsfaktor*faktor"],
          ["erzeugterStrom", "erzeugterStrom*faktor"],
          ["erzeugteWaerme", "erzeugteWaerme*faktor"],
          ["fahrleistung", "fahrleistung*faktor"],
          ["foerdervolumen", "foerdervolumen*faktor"],
          ["kundenanzahl", "kundenanzahl*faktor"],
          ["menge", "menge*faktor"],
          ["nennleistung", "nennleistung*faktor"],
          ["oekofondAufschlag", "oekofondAufschlag*faktor"],
          ["oekofondEinnahmen", "oekofondEinnahmen*faktor"],
          ["personenkm", "personenkm*faktor"],
        ],
      },
    });

    $parse.ui.setDefaultView(UnterKategorie.className, {
      type: "table",
    });

    $parse.ui.setClassConfig({
      className: Massnahme.className,
      translationPrefix: "klimazaehler:classes.",
      disableACLEditing: true,
      titleFields: ["bezeichnung"],
      displayFields: ["bezeichnung"],
      createFields: ["bezeichnung"],
      editFields: ["bezeichnung", "startDatum", "endDatum"],
    });

    $parse.ui.setDefaultView(Massnahme.className, {
      type: "table",
    });

    $parse.ui.setClassConfig({
      className: MassnahmeBerechnung.className,
      translationPrefix: "klimazaehler:classes.",
      disableACLEditing: true,
      titleFields: ["jahr"],
      displayFields: ["jahr"],
      createFields: ["jahr"],
      editFields: ["jahr"],
      asSelect: {
        kundengruppe: [
          ["Gesamt", "Gesamt"],
          ["Privat", "Privat"],
          ["Gewerbe", "Gewerbe"],
          ["Sondervertrag", "Sondervertrag"],
        ],
      },
    });

    $parse.ui.setDefaultView(MassnahmeBerechnung.className, {
      type: "table",
    });

    $parse.ui.setClassConfig({
      className: MassnahmeNachweis.className,
      translationPrefix: "klimazaehler:classes.",
      disableACLEditing: true,
      titleFields: ["bezeichnung"],
      displayFields: ["bezeichnung"],
      createFields: ["jahr", "bezeichnung", "datei"],
      editFields: ["jahr", "bezeichnung", "datei"],
    });

    $parse.ui.setDefaultView(MassnahmeNachweis.className, {
      type: "table",
    });

    $parse.ui.setClassConfig({
      className: TenantConfig.className,
      translationPrefix: "klimazaehler:classes.",
      disableACLEditing: true,
      titleFields: ["bezeichnung"],
      displayFields: [
        "primaryColor",
        "secondaryColor",
        "backgroundColor",
        "backgroundDimmColor",
        "backgroundImage",
        "handprintColor",
        "footprintActualColor",
        "footprintTargetColor",
        "comparativeFigures",
        "startOfConsider",
        "numberOfCustomers",
        "coverageAreaName",
        "coverageAreaSize",
        "coverageAreaPopulation",
        "memberOfKlimaschutzInitiative",
      ],
      createFields: [
        "primaryColor",
        "secondaryColor",
        "backgroundColor",
        "backgroundDimmColor",
        "backgroundImage",
        "handprintColor",
        "footprintActualColor",
        "footprintTargetColor",
        "comparativeFigures",
        "startOfConsider",
        "numberOfCustomers",
        "coverageAreaName",
        "coverageAreaSize",
        "coverageAreaPopulation",
        "memberOfKlimaschutzInitiative",
      ],
      editFields: [
        "primaryColor",
        "secondaryColor",
        "backgroundColor",
        "backgroundDimmColor",
        "backgroundImage",
        "handprintColor",
        "footprintActualColor",
        "footprintTargetColor",
        "comparativeFigures",
        "startOfConsider",
        "numberOfCustomers",
        "coverageAreaName",
        "coverageAreaSize",
        "coverageAreaPopulation",
        "memberOfKlimaschutzInitiative",
      ],
      customFields: {
        startOfConsider: EditStartYear
      },
      asSelect: {
        comparativeFigures: [
          ["co2", "CO₂"],
          ["tree", "Bäume"],
          ["plane", "Flugreisen"],
          ["car", "Weltumrundungen"],
          ["snowflake", "Arktisfläche"],
          ["steak", "Rindfleisch"],
        ],
      },
    });

    $parse.ui.setDefaultView(TenantConfig.className, {
      type: "table",
    });

    $parse.ui.setClassConfig({
      className: KlimaKachel.className,
      translationPrefix: "klimazaehler:classes.",
      disableACLEditing: true,
      titleFields: ["bezeichnung"],
      displayFields: ["kategorie", "bezeichnung", "iconPath"],
      createFields: ["kategorie", "bezeichnung", "iconPath"],
      editFields: ["kategorie", "bezeichnung", "iconPath"],
      asSelect: {
        iconPath: [
          ["Auto.svg", "Auto.svg"],
          ["BHKW.svg", "BHKW.svg"],
          ["BHKW_privat.svg", "BHKW_privat.svg"],
          ["Biogas_Absatz.svg", "Biogas_Absatz.svg"],
          ["Biogas_Erzeugung.svg", "Biogas_Erzeugung.svg"],
          ["Biogas_Erzeugung_privat.svg", "Biogas_Erzeugung_privat.svg"],
          ["CO2-Kompensation.svg", "CO2-Kompensation.svg"],
          ["CO2.svg", "CO2.svg"],
          ["Deponiegas.svg", "Deponiegas.svg"],
          ["E-Ladesäule.svg", "E-Ladesäule.svg"],
          ["Elektroauto.svg", "Elektroauto.svg"],
          ["Energieberatung.svg", "Energieberatung.svg"],
          ["EnMS_50001.svg", "EnMS_50001.svg"],
          ["Erdgasauto.svg", "Erdgasauto.svg"],
          ["Erdgastankstelle.svg", "Erdgastankstelle.svg"],
          ["Fernwärme.svg", "Fernwärme.svg"],
          ["Gepflanzte-Baeume.svg", "Gepflanzte-Baeume.svg"],
          ["H2O-Auto.svg", "H2O-Auto.svg"],
          ["Haushaltsgräte.svg", "Haushaltsgräte.svg"],
          ["Heizung_Gas.svg", "Heizung_Gas.svg"],
          ["Hocheffizienzpumpe.svg", "Hocheffizienzpumpe.svg"],
          ["Klimaneutraler-Strom.svg", "Klimaneutraler-Strom.svg"],
          ["Klimaneutrales-Erdgas.svg", "Klimaneutrales-Erdgas.svg"],
          ["Klärgas.svg", "Klärgas.svg"],
          ["LED-Lampen.svg", "LED-Lampen.svg"],
          ["Photovoltaik.svg", "Photovoltaik.svg"],
          ["Photovoltaik_privat.svg", "Photovoltaik_privat.svg"],
          ["Straßenbeleuchtung.svg", "Straßenbeleuchtung.svg"],
          ["Waermepumpe.svg", "Waermepumpe.svg"],
          ["Wasserkraft.svg", "Wasserkraft.svg"],
          ["Wasserkraft_privat.svg", "Wasserkraft_privat.svg"],
          ["Windkraft.svg", "Windkraft.svg"],
          ["Windkraft_privat.svg", "Windkraft_privat.svg"],
          ["Ökostrom.svg", "Ökostrom.svg"],
          ["ÖPNV.svg", "ÖPNV.svg"],
        ],
      },
    });

    $parse.ui.setDefaultView(KlimaKachel.className, {
      type: "table",
    });

    $parse.ui.setClassConfig({
      className: KlimaKachelEinstellungen.className,
      translationPrefix: "klimazaehler:classes.",
      disableACLEditing: true,
      titleFields: ["kachel"],
      displayFields: ["kachel", "url"],
      createFields: ["kachel", "url"],
      editFields: ["kachel", "url"],
    });

    $parse.ui.setDefaultView(KlimaKachelEinstellungen.className, {
      type: "table",
    });

    $parse.ui.setClassConfig({
      className: KlimaKurveJahr.className,
      translationPrefix: "klimazaehler:classes.",
      disableACLEditing: true,
      titleFields: ["year"],
      displayFields: [
        "year",
        "footprintActual",
        "footprintTarget",
        "scope1",
        "scope2",
        "scope3",
      ],
      createFields: [
        "year",
        "footprintActual",
        "footprintActualText",
        "footprintTarget",
        "footprintTargetText",
        "handprintText",
        "scope1",
        "scope2",
        "scope3",
      ],
      editFields: [
        "year",
        "footprintActual",
        "footprintActualText",
        "footprintTarget",
        "footprintTargetText",
        "handprintText",
        "scope1",
        "scope2",
        "scope3",
      ],
    });

    $parse.ui.setDefaultView(KlimaKurveJahr.className, {
      type: "table",
    });

    // Navigation

    const routes = [
      {
        id: "klimazaehler/super-admin",
        label: "Administration",
        children: [
          // Super Admin
          {
            label: "Mandanten",
            url: "/admin/parse/OD3_Tenant",
            permission: "asew:klimazaehler:super-admin",
          },
          {
            label: "Nutzer",
            url: "/admin/parse/_User",
            permission: "asew:klimazaehler:super-admin",
          },
          {
            label: "Oberkategorien",
            url: "/admin/parse/KlimaZaehler_Kategorie",
            permission: "asew:klimazaehler:super-admin",
          },
          {
            label: "Unterkategorien",
            url: "/admin/parse/KlimaZaehler_UnterKategorie",
            permission: "asew:klimazaehler:super-admin",
          },
          {
            label: "Vermeidungsfaktoren",
            url: "/asew/klimazaehler/faktoren",
            permission: "asew:klimazaehler:super-admin",
          },
          {
            label: "Maßnahmenzähler Kacheln",
            url: "/admin/parse/KlimaZaehler_KlimaKachel",
            permission: "asew:klimazaehler:super-admin",
          },
        ],
      },
      {
        id: "klimazaehler/tenant-admin-general",
        label: "Allgemein",
        children: [
          // Tenant Admin:
          {
            label: "Start",
            url: "/asew/klimazaehler/start",
            permission: "asew:klimazaehler:tenant-admin",
          },
          {
            label: "Maßnahmen",
            url: "/asew/klimazaehler/massnahmen",
            permission: "asew:klimazaehler:tenant-admin",
          },
          {
            label: "Vermeidungsfaktoren",
            url: "/asew/klimazaehler/faktoren",
            permission: "asew:klimazaehler:tenant-admin",
          },
        ],
      },
      {
        id: "klimazaehler/tenant-admin-output",
        label: "Ausgabe",
        children: [
          {
            label: "Gesamtzähler",
            url: "/asew/klimazaehler/ausgabe/gesamtzaehler",
            permission: "asew:klimazaehler:tenant-admin",
          },
          {
            label: "Maßnahmenzähler",
            url: "/asew/klimazaehler/ausgabe/massnahmenzaehler",
            permission: "asew:klimazaehler:tenant-admin",
          },
          {
            label: "Klimakurven",
            url: "/asew/klimazaehler/ausgabe/klimakurve",
            permission: "asew:klimazaehler:tenant-admin",
          },
        ],
      },
      {
        id: "klimazaehler/tenant-admin-settings",
        label: "Einstellungen",
        children: [
          {
            label: "Übergreifend",
            url: "/asew/klimazaehler/basisdaten",
            permission: "asew:klimazaehler:tenant-admin",
          },
          {
            label: "Maßnahmenzähler",
            url: "/admin/parse/KlimaZaehler_KlimaKachelEinstellungen",
            permission: "asew:klimazaehler:tenant-admin",
          },
          {
            label: "Klimakurven",
            url: "/admin/parse/KlimaZaehler_KlimaKurveJahr",
            permission: "asew:klimazaehler:tenant-admin",
          },
        ],
      },
    ];

    try {
      if(Parse && Parse.User && Parse.User.current() && Parse.User.current().id == "izjjZrs49r") {
        routes.pop();
      }
    } catch (error) {
      console.error("Filter Settings for WIPrüfung not working", error)
    }
    
    let i = 0;
    for (const group of routes) {
      factory.registerStaticNavigationGroup({
        id: group.id,
        label: group.label,
        // icon: "fa:hammer",
        order: i++,
      });

      let j = 0;
      for (const item of group.children) {
        factory.registerStaticNavigationItem({
          id: group.id + item.url,
          group: group.id,
          place: "sidebar",
          order: j++,

          label: item.label,
          // icon: "fa:home",

          link: item.url,

          routeCondition: "**",
          activeCondition: [item.url, item.url + "/**/*"],
          permission: item.permission,
        });
      }
    }

    // Translations:

    factory.registerTranslationResolver(
      "de",
      "klimazaehler",
      async () => await import("./translations/de.json")
    );
  }

  async onApp(app) {
    await translate("parse-admin:test");
    await translate("klimazaehler:test");

    const { SourceService } = app.services;

    SourceService.subscribe(() => {
      const source = SourceService.getCurrent();
      let storedSource = localStorage.getItem("awew:klimazaehler:current-source") || ""
      if (_optionalChain([source, 'optionalAccess', _ => _.tag, 'optionalAccess', _2 => _2.startsWith, 'call', _3 => _3("tenant-data-")])) {
        const id = source.tag.replace("tenant-data-", "");

        $state.setTenantFilter(id);
      } else {
        $state.setTenantFilter(null);
      }

      if(source.id != storedSource && storedSource != "") {
        localStorage.setItem("awew:klimazaehler:current-source", source.id.toString());
        location.reload();
      } 
      if(storedSource == "") {
        localStorage.setItem("awew:klimazaehler:current-source", source.id.toString());
        location.reload();
      }

    });
  }
}
